import moment from "moment";
import CreatePresetContentCard from "./CreatePresetContentCard";
import { LoopsResponse, useGenerateContentFromRevisionMutation, useLazyLoopsQuery } from "../../services/ContentApi";
import { useEffect, useState } from "react";
import PreviousDesignsGridCard from "./PreviousDesignsGridCards";
import { useNavigate } from "react-router-dom";
import {
  InspirationResponse,
  useLazyGetRandomInspirationsQuery,
} from "../../services/InspirationApi";
import InspirationsForQuickstartGrid from "./InspirationsForQuickstartGrid";
import { toggleContentWizard, toggleLoading } from "../../store/appSlice";
import { useDispatch } from "react-redux";
import { setNewContent } from "../../store/contentSlice";
import { ContentType } from "../../constants/ContentType";
import { ContentWizardView } from "../../models/ContentWizardView";
import { Mediums } from "../../constants/Mediums";
import { INewContent } from "../../models/NewContent";
import { useAppSelector } from "../../helpers/hooks";
import { ContentSize } from "../../constants/ContentSize";
import "./content-wizard.css";
import CustomLeftArrow from "./CustomLeftArrow";
import CustomRightArrow from "./CustomRightArrow";
import { Carousel } from "antd";
import { useWindowSize } from "@uidotdev/usehooks";
import { AICreateTabs } from "../../helpers/Utils";
import { Project, useListProjectsMutation } from "../../services/ProjectApi";


function ContentForYou() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const size = useWindowSize();
  const [items, setItems] = useState<Project[]>([]);
  const [favoriteItems, setFavoriteItems] = useState<LoopsResponse[]>([]);
  const [inspirations, setInspirations] = useState<InspirationResponse[]>([]);
  const lastSelectedBrandId = useAppSelector(
    (k) => k.persisted.lastSelectedBrandId
  );
  const newContent = useAppSelector((k) => k.content.newContent);
  const [
    getContentList,
    {
      isLoading: contentListLoading,
      isFetching: contentsFetching,
      data: contentList,
    },
  ] = useLazyLoopsQuery();

  const [
    generateContentFromRevision,
    {
      isLoading: generatingContentFromRevision,
      isSuccess: successGeneratingContentFromRevision,
    },
  ] = useGenerateContentFromRevisionMutation();
  const [
    getFavoritesContentList,
    {
      isLoading: favoritesContentListLoading,
      isFetching: favoritesContentsFetching,
      data: favoritesContentList,
    },
  ] = useLazyLoopsQuery();

  const [
    getInspirations,
    {
      isLoading: inspirationsLoading,
      isFetching: inspirationsFetching,
      data: inspirationsList,
    },
  ] = useLazyGetRandomInspirationsQuery();

  const [
    getProjects,
    {
      isLoading: projectListLoading,
      isSuccess: projectListSuccess,
      error: projectListError,
      data: projectList,
    },
  ] = useListProjectsMutation();

  useEffect(() => {
    if (projectList && projectList.length > 0) {
      setItems(projectList);
    }
  }, [projectList]);

  // useEffect(() => {
  //   if (favoritesContentList && favoritesContentList.length > 0) {
  //     setFavoriteItems(favoritesContentList);
  //   }
  // }, [favoritesContentList]);

  useEffect(() => {
    console.log("Change: ", inspirationsLoading, contentListLoading);
    dispatch(
      toggleLoading(
        inspirationsLoading ||
        contentListLoading ||
        inspirationsFetching ||
        contentsFetching ||
        generatingContentFromRevision ||
        favoritesContentsFetching ||
        favoritesContentListLoading
      )
    );
  }, [
    contentsFetching,
    inspirationsFetching,
    inspirationsLoading,
    contentListLoading,
    generatingContentFromRevision,
    favoritesContentsFetching,
    favoritesContentListLoading
  ]);

  // useEffect(() => {
  //   if (inspirationsList && inspirationsList.length > 0) {
  //     setInspirations(inspirationsList);
  //   }
  // }, [inspirationsList]);

  useEffect(() => {
    setItems([]);
    //setFavoriteItems([]);
    getLoopList(0);
    return () => {
      setItems([]);
      //setFavoriteItems([]);
    };
  }, []);

  // useEffect(() => {
  //   getInspirations();
  // }, [])

  const getLoopList = (page) => {
    var payload = {
      page: page,
      size: 20,
    };



    getProjects({ listProjectRequest: payload });
    // var favoritePayload = Object.assign({}, payload, { favorite: true });
    // getFavoritesContentList(favoritePayload);
  };

  const isMobile = () => {
    return size.width && size.width <= 500;
  };

  return (
    <div className="flex-col w-full h-full mt-2">
      <div className="flex-col w-[1050px]">
        <Carousel
          slidesToShow={isMobile() ? 1 : 6}
          nextArrow={<CustomRightArrow />}
          prevArrow={<CustomLeftArrow />}
          dots={false}
          arrows={true}
        >

          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.text,
                  wizardView: ContentWizardView.contentDetails,
                  medium: Mediums.find(
                    (k) =>
                      k.name === "Twitter" && k.contentType === ContentType.text
                  ),
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Twitter" && k.contentType === ContentType.text
                  )?.options?.at(0),
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                  isCreative: false,
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="X (Twitter) Post"
            sampleImagePath="content-x-post-sample.png"
          />
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.image,
                  wizardView: ContentWizardView.contentDetails,
                  contentSize: ContentSize.post,
                  medium: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  ),
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  )?.options?.at(8),
                  imageHeight: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  )?.options?.at(8)?.height,
                  imageWidth: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  )?.options?.at(8)?.width,
                  isCreative: false,
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                } as INewContent)
              );
              navigate(`/editor?type=${AICreateTabs.BANNER}&size=${ContentSize.post}`);
              //dispatch(toggleContentWizard());
            }}
            title="Post"
            sampleImagePath="content-instagram-post-sample.png"
          />
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.text,
                  wizardView: ContentWizardView.contentDetails,
                  medium: Mediums.find(
                    (k) =>
                      k.name === "Twitter" && k.contentType === ContentType.text
                  ),
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Twitter" && k.contentType === ContentType.text
                  )?.options?.at(1),
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                  isCreative: false,
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="X (Twitter) Thread"
            sampleImagePath="content-x-thread-sample.png"
          />
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.image,
                  wizardView: ContentWizardView.contentDetails,
                  contentSize: ContentSize.post,
                  medium: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  ),
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  )?.options?.at(10),
                  imageHeight: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  )?.options?.at(10)?.height,
                  imageWidth: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  )?.options?.at(10)?.width,
                  isCreative: false,
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                } as INewContent)
              );
              navigate(`/editor?type=${AICreateTabs.BANNER}&size=${ContentSize.story}`);
              //dispatch(toggleContentWizard());
            }}
            title="Story"
            sampleImagePath="content-instagram-story-sample.png"
          />
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.text,
                  wizardView: ContentWizardView.contentDetails,
                  medium: Mediums.find(
                    (k) =>
                      k.name === "LinkedIn" &&
                      k.contentType === ContentType.text
                  ),
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "LinkedIn" &&
                      k.contentType === ContentType.text
                  )?.options?.at(0),
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                  isCreative: false,
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="Linkedin Post"
            sampleImagePath="content-linkedin-post-sample.png"
          />
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.image,
                  wizardView: ContentWizardView.contentDetails,
                  medium: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  ),
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  )?.options?.at(6),
                  imageHeight: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  )?.options?.at(6)?.height,
                  imageWidth: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  )?.options?.at(6)?.width,
                  isCreative: false,
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                } as INewContent)
              );
              navigate(`/editor?type=${AICreateTabs.BANNER}&size=${ContentSize.mobileleaderb}`);
              //dispatch(toggleContentWizard());
            }}
            title="Mobile Leaderboard"
            sampleImagePath="content-mobile-leaderboard-sample.png"
          />
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.image,
                  wizardView: ContentWizardView.contentDetails,
                  contentSize: ContentSize.post,
                  medium: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  ),
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  )?.options?.at(9),
                  imageHeight: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  )?.options?.at(9)?.height,
                  imageWidth: Mediums.find(
                    (k) =>
                      k.name === "Banner" && k.contentType === ContentType.image
                  )?.options?.at(9)?.width,
                  isCreative: false,
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                } as INewContent)
              );
              navigate(`/editor?type=${AICreateTabs.BANNER}&size=${ContentSize.landscape}`);
              //dispatch(toggleContentWizard());
            }}
            title="Landscape"
            sampleImagePath="content-banner-landscape-sample.png"
          />
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.text,
                  wizardView: ContentWizardView.contentDetails,
                  medium: Mediums.find(
                    (k) =>
                      k.name === "Instagram" &&
                      k.contentType === ContentType.text
                  ),
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Instagram" &&
                      k.contentType === ContentType.text
                  )?.options?.at(0),
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                  isCreative: false,
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="Instagram Post"
            sampleImagePath="content-instagram-template-post-sample.png"
          />
        </Carousel>
      </div>

      {items?.length > 0 && (
        <>
          <div className="mt-[50px] mb-[20px]">
            <span className="text-H4 font-medium text-black  w-72 sm:w-full truncate text-ellipsis overflow-hidden">
              My Recent Designs
            </span>
            <hr className="bg-border mt-1 w-[1050px]" />
          </div>
          <div className="flex-col w-[1050px]">
            <Carousel
              slidesToShow={isMobile() ? 1 : 5}
              nextArrow={<CustomRightArrow />}
              prevArrow={<CustomLeftArrow />}
              dots={false}
              arrows={items?.length > 5}
            >
              {items.map((k, index) => (
                <PreviousDesignsGridCard
                  key={index}
                  project={k}
                  updateLoops={() => { }}
                  onSelect={(project_id) => {
                    navigate(`/editor?project_id=` + project_id);
                  }}
                />
              ))}
            </Carousel>
          </div>
        </>
      )}

      {/*inspirations?.length > 0 && (
        <>
          <div className="mt-[50px] mb-[20px]">
            <span className="text-H4 font-medium text-black  w-72 sm:w-full truncate text-ellipsis overflow-hidden">
              Inspirations For Quick Start
            </span>
            <hr className="bg-border mt-1 w-[1050px]" />
          </div>
          <InspirationsForQuickstartGrid
            onClick={(revisionId) => {
              if (revisionId !== "") {
                generateContentFromRevision({ revisionId: revisionId })
                  .unwrap()
                  .then((k) => {
                    const contentId = k.content_id ?? "";
                    navigate(`/app/loopdetails/` + contentId);
                  });
              }
            }}
            onRefresh={() => {
              getInspirations();
            }}
            inspirations={inspirations}
          />
        </>
      )}

      {favoriteItems?.length > 0 && (
        <>
          <div className="mt-[50px] mb-[20px]">
            <span className="text-H4 font-medium text-black  w-72 sm:w-full truncate text-ellipsis overflow-hidden">
              My Favorites
            </span>
            <hr className="bg-border mt-1 w-[1050px]" />
          </div>
          <div className="flex-col w-[1050px]">
            <Carousel
              slidesToShow={isMobile() ? 1 : 6}
              nextArrow={<CustomRightArrow />}
              prevArrow={<CustomLeftArrow />}
              dots={false}
              arrows={favoriteItems?.length > 6}
            >
              {favoriteItems.map((k, index) => (
                <PreviousDesignsGridCard
                  key={index}
                  loop={k}
                  updateLoops={() => { }}
                  onSelect={(contentId) => {
                    navigate(`/app/loopdetails/` + contentId);
                  }}
                />
              ))}
            </Carousel>
          </div>
        </>
      )*/}

    </div>
  );
}

export default ContentForYou;
