import { SVGProps, useState } from "react";
import SvgBackgroundRemover from "../../assets/IconComponents/BackgroundRemoverIcon";
import SvgLogoExtractor from "../../assets/IconComponents/LogoExtractorIcon";
import SvgTextToSpeech from "../../assets/IconComponents/SvgTextToSpeechIcon";
import SvgProductPhotoShoot from "../../assets/IconComponents/SvgProductPhotoShootIcon";
import SvgBannerGenerator from "../../assets/IconComponents/SvgBannerGeneratorIcon";
import SvgCreativeAdGenerator from "../../assets/IconComponents/SvgCreativeAdGeneratorIcon";
import SvgVideoAdGeneratorIcon from "../../assets/IconComponents/SvgVideoAdGeneratorIcon";
import { useAppSelector } from "../../helpers/hooks";
import SvgAdCopyOrGiftGenerator from "../../assets/IconComponents/SvgAdCopyGeneratorIcon";
import MarketingSuiteCard from "./components/MarketingSuiteCard";
import { useDispatch } from "react-redux";
import { setNewContent } from "../../store/contentSlice";
import { ContentType } from "../../constants/ContentType";
import { ContentWizardView } from "../../models/ContentWizardView";
import { Mediums } from "../../constants/Mediums";
import moment from "moment";
import { INewContent } from "../../models/NewContent";
import { toggleContentWizard } from "../../store/appSlice";
import { useNavigate } from "react-router-dom";

interface NavigationItem {
  title: string;
  description: string;
  disabled: boolean;
  route: string;
  icon?: ((props: SVGProps<SVGSVGElement>) => JSX.Element) | undefined;
  color?: string;
  bgColor?: string;
  onClick?: () => void;
}

const MarketingSuites = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const navigate = useNavigate()
  const collapsedModeEnabled = useAppSelector(
    (state) => state.app.collapsedModeEnabled
  );
  const dispatch = useDispatch();
  const lastSelectedBrandId = useAppSelector(
    (k) => k.persisted.lastSelectedBrandId
  );

  const navigationList: NavigationItem[] = [
    {
      title: "AI Background Remover",
      description: "Clean up logo & product backgrounds",
      disabled: false,
      route: "/app/background-remover",
      icon: SvgBackgroundRemover,
      color: "#D54A26",
      bgColor: "rgba(213, 74, 38, 0.1)",
    },
    {
      title: "AI Text to Speech Generator",
      description: "Convert text into natural speech",
      disabled: false,
      route: "/app/text-to-speech",
      color: "#F78292",
      bgColor: "rgba(247, 130, 146, 0.1)",
      icon: SvgTextToSpeech,
    },
    {
      title: "AI Product Photoshoot",
      description: "Sell more with studio-quality shoots",
      disabled: false,
      route: "/app/home?activeGenerator=4",
      color: "#008297",
      bgColor: "rgba(0, 130, 151, 0.1)",
      icon: SvgProductPhotoShoot,
    },
    {
      title: "AI Banner Generator",
      description: "Design custom banners at scale",
      disabled: false,
      route: "/app/home?activeGenerator=2",
      color: "#964EA2",
      bgColor: "rgba(150, 78, 162, 0.1)",
      icon: SvgBannerGenerator,
    },
    {
      title: "AI Creative Ad Generator",
      description: "Create scroll-stoping guerilla ads",
      disabled: false,
      route: "/app/home?activeGenerator=3",
      color: "#E39D16",
      bgColor: "rgba(227, 157, 22, 0.1)",
      icon: SvgCreativeAdGenerator,
    },
    {
      title: "AI Ad Copy Generator",
      description: "Generate ad copy that converts",
      disabled: false,
      route: "/app/home?activeGenerator=1",
      color: "#24874B",
      bgColor: "rgba(36, 135, 75, 0.1)",
      icon: SvgAdCopyOrGiftGenerator,
    },
    {
      title: "AI Video Ad Generator",
      description: "Create video ads and product videos",
      disabled: false,
      route: "/app/home?activeGenerator=5",
      color: "#24874B",
      bgColor: "rgba(153, 153, 153, 0.1)",
      icon: SvgVideoAdGeneratorIcon,
      onClick: () => {
        dispatch(
          setNewContent({
            sliderCount: 1,
            contentType: ContentType.video,
            video_type: "Video",
            video_length: 5,
            video_resolution: "1280x720 (720p HD)",
            wizardView: ContentWizardView.videoGeneration,
            mediumOption: Mediums.find(
              (k) => k.name === "Video" && k.contentType === ContentType.video
            )?.options?.at(0),
            medium: Mediums.find(
              (k) => k.name === "Video" && k.contentType === ContentType.video
            ),
            isCreative: true,
            loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
            brand: { id: lastSelectedBrandId },
          } as INewContent)
        );
        //dispatch(toggleContentWizard());
        navigate("/app/home?activeGenerator=5")
      },
    },
    {
      title: "AI GIF Generator",
      description: "Create custom GIFs for email campaigns",
      disabled: false,
      route: "/app/home?activeGenerator=5",
      color: "#24874B",
      bgColor: "rgba(36, 135, 75, 0.1)",
      icon: SvgAdCopyOrGiftGenerator,
      onClick: () => {
        dispatch(
          setNewContent({
            sliderCount: 1,
            contentType: ContentType.video,
            video_type: "Gif",
            video_length: 5,
            video_resolution: "1280x720 (720p HD)",
            wizardView: ContentWizardView.videoGeneration,
            mediumOption: Mediums.find(
              (k) => k.name === "Video" && k.contentType === ContentType.video
            )?.options?.at(1),
            medium: Mediums.find(
              (k) => k.name === "Video" && k.contentType === ContentType.video
            ),
            isCreative: true,
            loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
            brand: { id: lastSelectedBrandId },
          } as INewContent)
        );
        //dispatch(toggleContentWizard());
        navigate("/app/home?activeGenerator=5")
      },
    },
    // {
    //   title: "AI Graphic Generator",
    //   description: "Attract more with graphics and mascots",
    //   disabled: true,
    //   route: "",
    //   color: "#24874B",
    //   bgColor: "rgba(153, 153, 153, 0.1)",
    //   icon: SvgVideoAdGeneratorIcon,
    // },
    // {
    //   title: "AI Branding & Logo Extractor",
    //   description: "Collect brand elements from any website",
    //   disabled: true,
    //   route: "/app/content-calendar",
    //   icon: SvgLogoExtractor,
    //   color: "#7958CF",
    //   bgColor: "rgba(121, 88, 207, 0.1)",
    // },
  ];

  return (
    <>
      <div className="mt-[50px] mb-[20px]">
        <span className="text-H4 font-medium text-black  w-72 sm:w-full truncate text-ellipsis overflow-hidden">
          AI Marketing Suite
        </span>
        <hr className="bg-border mt-1" />
      </div>

      <div className="flex flex-row flex-wrap">
        {navigationList.length > 0 &&
          navigationList.map((item, index) => (
            <MarketingSuiteCard
              key={index}
              title={item.title}
              description={item.description}
              disabled={item.disabled}
              route={item.route}
              icon={item.icon}
              color={item.color}
              bgColor={item.bgColor}
              isHovered={index === hoveredIndex}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              collapsedModeEnabled={collapsedModeEnabled}
              onClick={item.onClick}
            />
          ))}
      </div>
    </>
  );
};

export default MarketingSuites;
