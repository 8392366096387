import * as React from "react";
import type { SVGProps } from "react";
const SquareFilterIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="338" height="337" viewBox="0 0 338 337" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M337.5 0H0.5V71H337.5V0Z" fill="black" fill-opacity="0.5"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M337.5 266H0.5V337H337.5V266Z" fill="black" fill-opacity="0.5"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V337H71V0H0Z" fill="black" fill-opacity="0.5"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M266 0V337H337V0H266Z" fill="black" fill-opacity="0.5"/>
    </svg>
);
export default SquareFilterIcon;
