import { useState } from "react";
import { Like, Liked } from "../../assets/IconComponents";
import ContentTypeSelector, {
  IContentTypeData,
} from "../../components/ContentTypeSelector/ContentTypeSelector";
import ChannelSelector, {
  IChannelTypeData,
} from "../../components/ChannelSelector/ChannelSelector";
import Icon from "../../components/Icon";
import PrimaryButton from "../../components/PrimaryButton";
import BrandSelector from "../../components/BrandSelector/BrandSelector";
import ChannelContentSelector, {
  IChannelContentData,
} from "../../components/ChannelContentSelector/ChannelContentSelector";

export interface IProjectsFilterProps {
  defaultSelectedChannels: string[];
  defaultSelectedContentTypes: string[];
  defaultSelectedChannelContentTypes: string[];
  defaultSelectedBrand: string | undefined;
  favoriteSelected: boolean;
  onChannelsChanged: (channels: IChannelTypeData[]) => void;
  onContentTypeChanged: (item: IContentTypeData) => void;
  onChannelContentTypesChanged: (items: IChannelContentData[]) => void;
  onBrandChanged: (item: string | undefined) => void;
  isFavorited: (status: boolean) => void;
}

function ProjectsFilter(props: IProjectsFilterProps) {
  const {
    defaultSelectedChannels,
    defaultSelectedContentTypes,
    defaultSelectedChannelContentTypes,
    defaultSelectedBrand,
    favoriteSelected = false,
    onChannelsChanged,
    isFavorited,
  } = props;

  const [selectedChannels, setSelectedChannels] = useState(
    defaultSelectedChannels
  );
  const [selectedContentType, setSelectedContentType] = useState<string>(
    defaultSelectedContentTypes[0]
  );
  const [selectedBrand, setSelectedBrand] = useState(defaultSelectedBrand);
  const [selectedChannelContentIds, setSelectedChannelContentIds] = useState<
    string[]
  >(defaultSelectedChannelContentTypes);

  return (
    <div className="w-[calc(100vw-30px)] max-lg:ml-1 lg:w-full flex flex-col lg:flex-row  items-center border-border ">
      <div className="w-full lg:w-full flex flex-col lg:flex-row lg:justify-between">
        <div className="flex flex-col lg:flex-row">
          <div className="w-full flex flex-col md:flex-row lg:mr-2 items-center gap-[6px]">
            <div className="w-full flex flex-row items-center ">
              <BrandSelector
                className="h-11"
                BrandSelected={(e) => {
                  setSelectedBrand(e === undefined ? undefined : e.id);
                  props.onBrandChanged(e === undefined ? undefined : e.id);
                }}
              />
              {/* <ContentTypeSelector
                className="ml-2 h-11"
                closeOnSelect
                selectedContentType={selectedContentType}
                contentTypesSelected={(e) => {
                  setSelectedContentType(e.id);
                  props.onContentTypeChanged(e);
                }}
              /> */}
            </div>

            {/* <div className="flex lg:hidden flex-row border-border border-1 bg-white h-[33px] w-[33px] ml-0 lg:ml-2 rounded-full items-center justify-center">
                            <PrimaryButton
                                noFill
                                iconComponent={
                                    likeSelected ?
                                        <Icon width="20" height="20" component={Liked} color={"#FF5555"} /> :
                                        <Icon width="20" height="20" component={Like} color="#999999" />
                                }
                                className="h-[12px] w-[12px] !rounded-full !border-0"
                                onClick={() => {
                                    setLikeSelected(!likeSelected)
                                    props.isFavorited(!likeSelected)
                                }} />

                        </div>
                    </div>
                    <div className="w-full flex flex-row items-center mt-2 lg:mt-0"> */}
            {/* <div className="w-full flex flex-row items-center ">
              <ChannelSelector
                deselectable
                closeOnSelect
                className="h-11"
                defaultValues={selectedChannels}
                channelsSelected={(e) => {
                  setSelectedChannels(e?.map((channel) => channel.title) ?? []);
                  if (e?.length === 0) {
                    setSelectedChannelContentIds([]);
                  }
                  onChannelsChanged(e ?? []);
                }}
              />

              {selectedChannels && selectedChannels.length > 0 && (
                <ChannelContentSelector
                  className="h-11"
                  deselectable={false}
                  closeOnSelect={true}
                  defaultValues={selectedChannelContentIds ?? ["1"]}
                  filterChannels={selectedChannels ?? ["twitter"]}
                  channelContentsSelected={(
                    e: IChannelContentData[] | undefined
                  ) => {
                    e &&
                      setSelectedChannelContentIds(
                        e.map((content) => content.id) ?? []
                      );
                    props.onChannelContentTypesChanged(e ?? []);
                  }}
                />
              )}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsFilter;
