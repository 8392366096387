import ContentContainer from "../../components/ContentContainer";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../helpers/hooks";
import {
  useDeleteContentMutation,
  useEditNameMutation,
} from "../../services/ContentApi.old/ContentApi";
import {
  useEditTextMutation,
  useFavoriteMutation,
} from "../../services/TextApi.old/TextApi";
import ContentImage from "../../components/ContentImage";
import { useFavoriteImageMutation } from "../../services/ImageApi.old/ImageApi";
import {
  Revision,
  useFavorite1Mutation,
} from "../../services/ContentApi";
import { useWindowSize } from "@uidotdev/usehooks";
import { Project } from "../../services/ProjectApi";

export interface IPreviousDesignsGridCardProps {
  project: Project;
  updateLoops: () => void;
  onSelect: (contentId: string) => void;
}

function PreviousDesignsGridCard(props: IPreviousDesignsGridCardProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [updateText, { isSuccess: textUpdated }] = useEditTextMutation();
  const [editName, { isSuccess: nameUpdated }] = useEditNameMutation();

  const [deleteContent, { isSuccess: contentDeleted }] =
    useDeleteContentMutation();
  const [setTextFavorite, { isSuccess: textFavoriteUpdated }] =
    useFavoriteMutation();
  const [setImageFavorite, { isSuccess: imageFavoriteUpdated }] =
    useFavoriteImageMutation();
  const [updateFav, { isSuccess: favUpdated }] = useFavorite1Mutation();

  const [isFavori, setFavori] = useState(false);
  const [cardContent, setCardContent] = useState<Revision>();
  const size = useWindowSize();
  const [videoType, setVideoType] = useState<"Gif" | "Video">("Video");




  const getTruncatedText = (text: string) => {
    let truncationLimit = 40;

    if (size.width && size.width > 1350 && size.width < 1600)
      truncationLimit = 30;
    else if (size.width && size.width > 580 && size.width <= 1350)
      truncationLimit = 20;
    return `${(text.length ?? 0) > truncationLimit
      ? text.slice(0, truncationLimit) + "..."
      : text
      }`;
  };

  return (
    <div className="flex flex-col gap-[5px]">
      <ContentContainer
        className={`  h-[215px] cursor-pointer mx-1`}
        onClick={() => {
          props.onSelect(props.project.id?.project_id ?? "");
        }}
        hoverable
      >
        <div className={`flex w-full flex-col items-start gap-[10px] p-0.5`}>

          <ContentImage
            id={props.project.generatedContent ?? ""}
            className="py-1 h-[210px] bg-bg w-full rounded-[8px] object-contain"
          />

        </div>
      </ContentContainer>
      <div className={`flex flex-col mt-1 ml-1 justify-start w-full`}>
        <span className="text-BodyMedium font-inter-tight font-medium text-black">
          {getTruncatedText(props.project.name ?? "")}
        </span>
      </div>
    </div>
  );
}

export default PreviousDesignsGridCard;
