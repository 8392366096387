import * as React from "react";
import type { SVGProps } from "react";
const CTASvg = (props: SVGProps<SVGSVGElement>) => (
    <svg

        width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <path d="M3.33317 16.6667C2.87484 16.6667 2.48248 16.5035 2.15609 16.1771C1.8297 15.8507 1.6665 15.4584 1.6665 15V5.00004C1.6665 4.54171 1.8297 4.14935 2.15609 3.82296C2.48248 3.49657 2.87484 3.33337 3.33317 3.33337H16.6665C17.1248 3.33337 17.5172 3.49657 17.8436 3.82296C18.17 4.14935 18.3332 4.54171 18.3332 5.00004V15C18.3332 15.4584 18.17 15.8507 17.8436 16.1771C17.5172 16.5035 17.1248 16.6667 16.6665 16.6667H3.33317ZM3.33317 15H16.6665V5.00004H3.33317V15ZM6.24984 14.1667H13.7498C14.0971 14.1667 14.3922 14.0452 14.6353 13.8021C14.8783 13.5591 14.9998 13.2639 14.9998 12.9167C14.9998 12.5695 14.8783 12.2743 14.6353 12.0313C14.3922 11.7882 14.0971 11.6667 13.7498 11.6667H6.24984C5.90261 11.6667 5.60748 11.7882 5.36442 12.0313C5.12136 12.2743 4.99984 12.5695 4.99984 12.9167C4.99984 13.2639 5.12136 13.5591 5.36442 13.8021C5.60748 14.0452 5.90261 14.1667 6.24984 14.1667Z"/>
    </svg>
);
export default CTASvg;
