import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import { setActiveItem, setActivePage, setDialog, setMenuCollapsed, toggleDialog, toggleLoading, toggleMenu, toggleBrandEditing } from "../../store/appSlice";
import { setAccessToken, setLastSelectedBrandId, setRefreshToken, toggleLoggedIn } from "../../store/persistedSlice";
import PrimaryButton from "../PrimaryButton";
import LeftMenuButton, { ButtonState } from "./LeftMenuButton";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ShimmerTitle } from "react-shimmer-effects";
import { Add, Home, LogoLight, Logout, Loops } from "../../assets/IconComponents";
import { Mediums } from "../../constants/Mediums";
import { clickOutside } from "../../helpers/ClickOutside";
import { useLazyLogoutQuery } from "../../services/AuthenticationApi.old/AuthenticationApi";
import { useLazyGetListQuery } from "../../services/ContentApi.old/ContentApi";
import { ContentId } from "../../services/ContentApi.old/Model/ContentModel";
import { updateMenu } from "../../store/appSlice";
import { setNewContent } from "../../store/contentSlice";
import Brand from "../../assets/IconComponents/Brand";
import SvgInspirations from "../../assets/IconComponents/Inspirations";
import SvgLogoIcon from "../../assets/IconComponents/LogoIcon";
import { Id, useLazyList5Query } from "../../services/ContentApi";
import { useWindowSize } from "@uidotdev/usehooks";
import SidebarMenuButton from "./SidebarMenuButton";
import SvgDefaultBrandLogoNew from "../../assets/IconComponents/DefaultBrandLogoNew";
import SvgSettings from "../../assets/IconComponents/Settings";

function Sidebar() {
  const [logoutUser, { isLoading: isLogoutLoading, isSuccess: isLogoutSuccess, isError: isLogoutError }] = useLazyLogoutQuery()
  const updateMenuEvent = useAppSelector(state => state.app.updateMenu)
  const collapsedModeEnabled = useAppSelector(state => state.app.collapsedModeEnabled)
  const isBrandEditing = useAppSelector((state) => state.app.brandEditing);
  const activePage = useAppSelector(state => state.app.activePage)
  const activeItem = useAppSelector(state => state.app.activeItem)
  const [getContentList, { isLoading: contenListLoading, data: contentList }] = useLazyList5Query();
  const dispatch = useAppDispatch()
  const [isDialogDisplay, setDialogDisplay] = useState(false)
  const navigate = useNavigate()
  const isLoggedIn = useAppSelector(state => state.persisted.isLoggedIn)
  const showMenu = useAppSelector(state => state.app.showMenu)
  const isExpanded = useAppSelector(state => state.app.isMenuCollapsed)
  const [logoutClosed, setLogoutClosed] = useState(true)
  const size = useWindowSize();
  const [localActiveItem, setLocalActiveItem] = useState("");

  let container: HTMLDivElement;

  const prepareMenu = () => {
    getContentList({
      page: 0,
      size: 999
    })
  }
  useEffect(() => {
    if (isLoggedIn)
      prepareMenu()
  }, [isLoggedIn])

  useEffect(() => {
    console.log("Logout", logoutClosed);
  }, [logoutClosed]);

  useEffect(() => {
    if (activeItem) {
      console.log("Active item:", activeItem);
      setLocalActiveItem(activeItem ?? "");
    }
  }, [activeItem]);

  useEffect(() => {
    prepareMenu()
  }, [updateMenuEvent])
  useEffect(() => {
    dispatch(updateMenu())
  }, [contentList])

  const showLoop = (id?: Id) => {
    navigate(`loopdetails/${id?.content_id}`)

    // navigate(`revisionhisory`, { state: { content_id: id.content_id } })
  }

  const cleanUp = () => {
    dispatch(setLastSelectedBrandId(undefined))
    dispatch(setNewContent(undefined))
  }

  const logout = () => {
    dispatch(setDialog({
      actions: <>
        <PrimaryButton noFill title={t('cancel')} onClick={() => {
          dispatch(toggleDialog(false))
          setLogoutClosed(true)
        }} />
        <PrimaryButton className="ml-2" title={t('logOut')} onClick={() => {
          cleanUp();
          dispatch(toggleDialog(false));

          dispatch(setActivePage(""))
          dispatch(setActiveItem(""))
          setLogoutClosed(true)
          logoutUser()
        }} />
      </>,
      title: t('log-out'),
      message: t('are-you-sure-you-would-like-to-log-out-of-your-evercopy-account'),
      onClose: () => { dispatch(toggleDialog(false)) }
    }))
    dispatch(toggleDialog(true))
  }

  // useEffect(() => {
  //     clickOutside(container, () => {
  //         dispatch(toggleMenu(false))
  //     })
  // }, [])


  useEffect(() => {
    if (isLogoutSuccess || isLogoutError) {
      dispatch(setAccessToken(""))
      dispatch(setRefreshToken(""))
      dispatch(toggleLoggedIn(false))
    }
  }, [isLogoutSuccess, isLogoutError])

  useEffect(() => {
    dispatch(toggleLoading(isLogoutLoading))
  }, [isLogoutLoading])

  const getMediaIcon = (mediaName: string) => {
    const platformName = mediaName.split(" | ")[0]
    const icon = Mediums.find(k => k.name === platformName)?.icon
    return icon
  }

  const isMobile = () => {
    return size.width && size.width <= 500
  }
  const exitWizard = (loopId: Id | undefined, clickType: number) => {
    dispatch(
      setDialog({
        message:
          t(
            "are-you-sure-you-want-to-leave-this-page-unsaved-changes-will-be-lost"
          ) ?? "",
        title: t("unsaved-changes"),
        actions: (
          <>
            <PrimaryButton
              className="h-12 w-[140px]"
              noFill
              title={t("cancel")}
              onClick={() => {
                dispatch(toggleDialog(false));
                dispatch(setActivePage("brandhub"));
              }}
            />
            <PrimaryButton
              className="h-12 w-[140px] ml-2"
              title={t("yes-leave")}
              onClick={() => {
                dispatch(toggleDialog(false));
                dispatch(toggleBrandEditing(false));
                if (clickType === 0) {
                  dispatch(setActivePage("homepage"));
                  dispatch(setActiveItem(loopId?.content_id));
                  dispatch(toggleMenu(false));
                  dispatch(toggleDialog(false));
                  showLoop(loopId);
                } else if (clickType === 1) {
                  dispatch(toggleMenu(false));
                  navigate(`loops`);
                } else if (clickType === 2) {
                  dispatch(setActivePage("inspirations"));
                  dispatch(setActiveItem(""));
                  dispatch(toggleMenu(false));
                  navigate(`inspirations`);
                } else if (clickType === 3) {
                  dispatch(setActivePage("brandhub"));
                  dispatch(setActiveItem(""));
                  dispatch(toggleMenu(false));
                  navigate(`brandhub`);
                } else if (clickType === 4) {
                  setLogoutClosed(false);
                  dispatch(toggleMenu(false));
                  logout();
                }

              }}
            />
          </>
        ),
        onClose: () => {
          dispatch(toggleDialog(false));
        },
      })
    );

    dispatch(toggleDialog(true));
  };


  //mobile open/close
  //loop detail auto open/close
  //normal show

  return (
    <>
      <div
        ref={(r) => (container = r!)}
        className={`${!showMenu ? "hidden lg:flex" : "lg:flex"
          } bg-menuBg  w-full h-full
            ${!(collapsedModeEnabled && !isExpanded && !isMobile())
            ? "lg:w-[240px]"
            : "lg:w-[0px]"
          }
            ${collapsedModeEnabled && !isMobile() ? "absolute" : ""}
             ${showMenu || (!isExpanded && !isMobile())
            ? "absolute top-[15px] left-0 right-0 lg:relative lg:top-0"
            : ""
          } 
             lg:h-screen  lg:max-h-screen overflow-hidden flex-col items-stretch justify-between z-[1000]`}
        onMouseLeave={() => {
          if (collapsedModeEnabled && !isMobile())
            dispatch(setMenuCollapsed(false));
        }}
        onMouseEnter={() => {
          if (collapsedModeEnabled && !isMobile())
            dispatch(setMenuCollapsed(true));
        }}
      >
        <div className="w-full flex items-center h-[56px] min-h-[56px] max-h-[56px]">
          {!(collapsedModeEnabled && !isExpanded && !isMobile()) && (
            <LogoLight
              width={150}
              height={24}
              preserveAspectRatio="xMinYMin meet"
              viewBox="0 0 150 75"
              className="ml-[18px]"
            />
          )}
          {collapsedModeEnabled && !isExpanded && (
            <SvgLogoIcon className="ml-[18px]" />
          )}
        </div>
        <hr className="mb-1 -mr-3" style={{ borderColor: "#1F2022" }} />

        {(!isMobile() ||
          (collapsedModeEnabled && isExpanded && isMobile()) ||
          showMenu) && (
            <>

              <div className="overflow-scroll scrollbar-hide flex flex-1 flex-col max-h-[300px] lg:max-h-full">
                {!contenListLoading &&
                  contentList?.map((loop, index) => {
                    return (
                      <LeftMenuButton
                        state={
                          activePage === "homepage" &&
                            activeItem === loop?.id?.content_id
                            ? ButtonState.focus
                            : ButtonState.normal
                        }
                        key={loop.id?.content_id}
                        deactiveColor="#FFFFFF"
                        textColor="text-white"
                        onClick={() => {
                          if (isBrandEditing) {
                            exitWizard(loop.id, 0);
                          } else {
                            dispatch(setActivePage("homepage"));
                            dispatch(setActiveItem(loop.id?.content_id));
                            dispatch(toggleMenu(false));
                            dispatch(toggleDialog(false));
                            showLoop(loop.id);
                          }
                        }}
                        title={loop.name ?? ""}
                        className="w-full"
                        icon={getMediaIcon(loop.platform ?? "")}
                      />
                    );
                  })}
                {contenListLoading && (
                  <ShimmerTitle
                    line={5}
                    gap={10}
                    variant="secondary"
                    className="w-36 ml-5 mt-5"
                  />
                )}
              </div>
            </>
          )}

        {(!contenListLoading && contentList?.length == 0) ||
          (collapsedModeEnabled && !isExpanded && isMobile()) ||
          (showMenu && <div className="flex flex-1"></div>)}

        {(!isMobile() ||
          (collapsedModeEnabled && isExpanded && isMobile()) ||
          showMenu) && (
            <div className="p-2 pt-0 pl-0 pb-[10px] max-lg:fixed max-lg:bottom-0">
              <hr
                className="mb-[10px] -mr-4"
                style={{ borderColor: "#EAEBE6" }}
              />
              <SidebarMenuButton
                state={
                  activePage === "projects" ? ButtonState.focus : ButtonState.normal
                }
                icon={Loops}
                deactiveColor="#000"
                onClick={() => {
                  if (isBrandEditing) {
                    exitWizard(undefined, 1);
                  } else {
                    dispatch(toggleMenu(false));
                    navigate(`projects`);
                  }
                }}
                title={t("contents")}
                className="w-full"
              />
              <SidebarMenuButton
                state={
                  activePage === "inspirations"
                    ? ButtonState.focus
                    : ButtonState.normal
                }
                icon={SvgInspirations}
                deactiveColor="#000"
                onClick={() => {
                  if (isBrandEditing) {
                    exitWizard(undefined, 2);
                  } else if (!logoutClosed) {
                    console.log("Herte");
                  } else {
                    dispatch(setActivePage("inspirations"));
                    dispatch(setActiveItem(""));
                    dispatch(toggleMenu(false));
                    navigate(`inspirations`);
                  }
                }}
                title={t("content-inspirations")}
                className="w-full"
              />
              <SidebarMenuButton
                state={
                  activePage === "brandhub"
                    ? ButtonState.focus
                    : ButtonState.normal
                }
                icon={SvgDefaultBrandLogoNew}
                deactiveColor="#000"
                onClick={() => {
                  if (isBrandEditing) {
                    exitWizard(undefined, 3);
                  } else if (!logoutClosed) {
                  } else {
                    dispatch(setActivePage("brandhub"));
                    dispatch(setActiveItem(""));
                    dispatch(toggleMenu(false));
                    navigate(`brandhub`);
                  }
                }}
                title={t("brand-hub")}
                className="w-full"
              />
              <SidebarMenuButton
                state={logoutClosed ? ButtonState.normal : ButtonState.focus}
                icon={Logout}
                deactiveColor="#000"
                onClick={() => {
                  if (isBrandEditing) {
                    exitWizard(undefined, 4);
                  } else {
                    setLogoutClosed(false);
                    logout();
                  }
                }}
                title={t("logOut")}
                className="w-full"
              />

              <SidebarMenuButton
                state={
                  activePage === "settings"
                    ? ButtonState.focus
                    : ButtonState.normal
                }
                icon={SvgSettings}
                deactiveColor="#000"
                onClick={() => {
                  if (isBrandEditing) {
                    exitWizard(undefined, 3);
                  } else {
                    dispatch(setActivePage("settings"));
                    dispatch(setActiveItem(""));
                    dispatch(toggleMenu(false));
                    navigate(`settings`);
                  }
                }}
                title={t("settings")}
                className="w-full"
              />

            </div>
          )}
      </div>
    </>
  );
}

export default Sidebar;