import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AICreateTabs } from "../helpers/Utils";


interface PolotnoState {
  isLoading: boolean
  formData: any
}

const initialState: PolotnoState = {
  isLoading: false,
  formData: {
    projectName: '',
    project: {}
  },
}

// to set dynamic tab data
Object.values(AICreateTabs).forEach(val => {
  initialState.formData[val] = {
    size: {
      label: 'Landscape', value: '1280x768', width: 1280,
      height: 768
    },
    description: '',
    duration: { label: '5sc', value: 5 },
    CTA: false,
    ADFormat: { label: '', value: '' },
    filters: { toneOfVoice: undefined, objective: undefined, audience: undefined },
    createdImages: [],
    selectedThemesImages: [],
    selectedInspirationImages: [],
    selectedLogos: []
  }
})

console.log(initialState.formData)
export const polotnoSlice = createSlice({
  name: "polotno",
  initialState,
  reducers: {
    setFormData: (state, formData: PayloadAction<any>) => {
      state.formData = formData.payload;
    },
    resetFormData: (state) => {
      const newData = {
        projectName: '',
        project: {}
      }
      Object.values(AICreateTabs).forEach(val => {
        newData[val] = {
          size: {
            label: 'Landscape', value: '1280x768', width: 1280,
            height: 768
          },
          description: '',
          duration: { label: '5sc', value: 5 },
          CTA: false,
          ADFormat: { label: '', value: '' },
          filters: { toneOfVoice: undefined, objective: undefined, audience: undefined },
          createdImages: [],
          selectedThemesImages: [],
          selectedInspirationImages: [],
          selectedLogos: []
        }
      })

      state.formData = newData;
    },
    setProject: (state, data: PayloadAction<any>) => {
      console.log(data.payload)
      state.formData.project = data.payload;
    },
    setCreatedImages: (state, data: PayloadAction<any>) => {
      state.formData[data.payload.tab].createdImages = data.payload.createdImages;
    }
  },
});

export const {
  setFormData,
  resetFormData,
  setProject,
  setCreatedImages
} = polotnoSlice.actions;

export const selectProjectName = (state) => state.polotno.formData.projectName

export default polotnoSlice.reducer
