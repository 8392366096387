import * as React from "react";
import type { SVGProps } from "react";
const AdFormatSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg

        width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M14.1665 12.4999H12.4998C12.2637 12.4999 12.0658 12.5798 11.9061 12.7395C11.7464 12.8992 11.6665 13.0971 11.6665 13.3333C11.6665 13.5694 11.7464 13.7673 11.9061 13.927C12.0658 14.0867 12.2637 14.1666 12.4998 14.1666H14.9998C15.2359 14.1666 15.4339 14.0867 15.5936 13.927C15.7533 13.7673 15.8332 13.5694 15.8332 13.3333V10.8333C15.8332 10.5971 15.7533 10.3992 15.5936 10.2395C15.4339 10.0798 15.2359 9.99992 14.9998 9.99992C14.7637 9.99992 14.5658 10.0798 14.4061 10.2395C14.2464 10.3992 14.1665 10.5971 14.1665 10.8333V12.4999ZM5.83317 7.49992H7.49984C7.73595 7.49992 7.93386 7.42006 8.09359 7.26033C8.25331 7.10061 8.33317 6.9027 8.33317 6.66658C8.33317 6.43047 8.25331 6.23256 8.09359 6.07284C7.93386 5.91311 7.73595 5.83325 7.49984 5.83325H4.99984C4.76373 5.83325 4.56581 5.91311 4.40609 6.07284C4.24636 6.23256 4.1665 6.43047 4.1665 6.66658V9.16658C4.1665 9.4027 4.24636 9.60061 4.40609 9.76033C4.56581 9.92006 4.76373 9.99992 4.99984 9.99992C5.23595 9.99992 5.43386 9.92006 5.59359 9.76033C5.75331 9.60061 5.83317 9.4027 5.83317 9.16658V7.49992ZM3.33317 16.6666C2.87484 16.6666 2.48248 16.5034 2.15609 16.177C1.8297 15.8506 1.6665 15.4583 1.6665 14.9999V4.99992C1.6665 4.54159 1.8297 4.14922 2.15609 3.82284C2.48248 3.49645 2.87484 3.33325 3.33317 3.33325H16.6665C17.1248 3.33325 17.5172 3.49645 17.8436 3.82284C18.17 4.14922 18.3332 4.54159 18.3332 4.99992V14.9999C18.3332 15.4583 18.17 15.8506 17.8436 16.177C17.5172 16.5034 17.1248 16.6666 16.6665 16.6666H3.33317ZM3.33317 14.9999H16.6665V4.99992H3.33317V14.9999Z"/>
    </svg>
);
export default AdFormatSvg;
