import moment from "moment";
import { Carousel } from "antd";
import CreatePresetContentCard from "./CreatePresetContentCard";
import {
  LoopsResponse,
  useGenerateContentFromRevisionMutation,
  useLazyLoopsQuery,
} from "../../services/ContentApi";
import { useEffect, useState } from "react";
import PreviousDesignsGridCard from "./PreviousDesignsGridCards";
import { useNavigate } from "react-router-dom";
import {
  InspirationResponse,
  useLazyGetRandomInspirationsQuery,
} from "../../services/InspirationApi";
import InspirationsForQuickstartGrid from "./InspirationsForQuickstartGrid";
import { toggleContentWizard, toggleLoading } from "../../store/appSlice";
import { useDispatch } from "react-redux";
import { setNewContent } from "../../store/contentSlice";
import { ContentType } from "../../constants/ContentType";
import { ContentWizardView } from "../../models/ContentWizardView";
import { Mediums } from "../../constants/Mediums";
import { INewContent } from "../../models/NewContent";
import { useAppSelector } from "../../helpers/hooks";
import { ContentSize } from "../../constants/ContentSize";
import CustomRightArrow from "./CustomRightArrow";
import CustomLeftArrow from "./CustomLeftArrow";
import { useWindowSize } from "@uidotdev/usehooks";
import { AICreateTabs } from "../../helpers/Utils";

function ContentPhotoshoot() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const size = useWindowSize();
  const [items, setItems] = useState<LoopsResponse[]>([]);
  const [favoriteItems, setFavoriteItems] = useState<LoopsResponse[]>([]);
  const [inspirations, setInspirations] = useState<InspirationResponse[]>([]);
  const lastSelectedBrandId = useAppSelector(
    (k) => k.persisted.lastSelectedBrandId
  );
  const newContent = useAppSelector((k) => k.content.newContent);
  const [
    getContentList,
    {
      isLoading: contentListLoading,
      isFetching: contentsFetching,
      data: contentList,
    },
  ] = useLazyLoopsQuery();

  const [
    generateContentFromRevision,
    {
      isLoading: generatingContentFromRevision,
      isSuccess: successGeneratingContentFromRevision,
    },
  ] = useGenerateContentFromRevisionMutation();
  const [
    getFavoritesContentList,
    {
      isLoading: favoritesContentListLoading,
      isFetching: favoritesContentsFetching,
      data: favoritesContentList,
    },
  ] = useLazyLoopsQuery();

  const [
    getInspirations,
    {
      isLoading: inspirationsLoading,
      isFetching: inspirationsFetching,
      data: inspirationsList,
    },
  ] = useLazyGetRandomInspirationsQuery();

  // useEffect(() => {
  //   if (contentList && contentList.length > 0) {
  //     setItems(contentList);
  //   }
  // }, [contentList]);

  // useEffect(() => {
  //   if (favoritesContentList && favoritesContentList.length > 0) {
  //     setFavoriteItems(favoritesContentList);
  //   }
  // }, [favoritesContentList]);

  // useEffect(() => {
  //   console.log("Change: ", inspirationsLoading, contentListLoading);
  //   dispatch(
  //     toggleLoading(
  //       inspirationsLoading ||
  //       contentListLoading ||
  //       inspirationsFetching ||
  //       contentsFetching ||
  //       generatingContentFromRevision ||
  //       favoritesContentsFetching ||
  //       favoritesContentListLoading
  //     )
  //   );
  // }, [
  //   contentsFetching,
  //   inspirationsFetching,
  //   inspirationsLoading,
  //   contentListLoading,
  //   generatingContentFromRevision,
  //   favoritesContentsFetching,
  //   favoritesContentListLoading,
  // ]);

  // useEffect(() => {
  //   if (inspirationsList && inspirationsList.length > 0) {
  //     setInspirations(inspirationsList);
  //   }
  // }, [inspirationsList]);

  // useEffect(() => {
  //   setItems([]);
  //   setFavoriteItems([]);
  //   getLoopList(0);
  //   return () => {
  //     setItems([]);
  //     setFavoriteItems([]);
  //   };
  // }, []);

  // useEffect(() => {
  //   getInspirations();
  // }, []);

  // const getLoopList = (page) => {
  //   var payload = {
  //     page: page,
  //     size: 20,
  //   };

  //   payload["contentType"] = "";
  //   payload["platform"] = "";
  //   payload["brandId"] = "";

  //   getContentList(payload);
  //   var favoritePayload = Object.assign({}, payload, { favorite: true });
  //   getFavoritesContentList(favoritePayload);
  // };

  const isMobile = () => {
    return size.width && size.width <= 500;
  };

  return (
    <div className="flex-col w-full h-full mt-2">
      <div className="flex-col w-[1050px]">
        <Carousel
          draggable={false}
          slidesToShow={isMobile() ? 1 : 6}
          nextArrow={<CustomRightArrow />}
          prevArrow={<CustomLeftArrow />}
          dots={false}
          arrows={isMobile() ? true : false}
        >
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.image,
                  wizardView: ContentWizardView.aiProductPhotoshoot,
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Product" &&
                      k.contentType === ContentType.image
                  )?.options?.at(0),
                  medium: Mediums.find(
                    (k) =>
                      k.name === "Product" &&
                      k.contentType === ContentType.image
                  ),
                  isCreative: false,
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                } as INewContent)
              );
              navigate(`/editor?type=${AICreateTabs.PHOTO_SHOOT}&size=0`);
              //dispatch(toggleContentWizard());
            }}
            title="Square"
            sampleImagePath="content-photoshoot-square.png"
          />

          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.image,
                  wizardView: ContentWizardView.aiProductPhotoshoot,
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Product" &&
                      k.contentType === ContentType.image
                  )?.options?.at(1),
                  medium: Mediums.find(
                    (k) =>
                      k.name === "Product" &&
                      k.contentType === ContentType.image
                  ),
                  isCreative: false,
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                } as INewContent)
              );
              navigate(`/editor?type=${AICreateTabs.PHOTO_SHOOT}&size=1`);
              //dispatch(toggleContentWizard());
            }}
            title="Landscape"
            sampleImagePath="content-photoshoot-landscape.png"
          />
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  contentType: ContentType.image,
                  wizardView: ContentWizardView.aiProductPhotoshoot,
                  mediumOption: Mediums.find(
                    (k) =>
                      k.name === "Product" &&
                      k.contentType === ContentType.image
                  )?.options?.at(2),
                  medium: Mediums.find(
                    (k) =>
                      k.name === "Product" &&
                      k.contentType === ContentType.image
                  ),
                  isCreative: false,
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                } as INewContent)
              );
              navigate(`/editor?type=${AICreateTabs.PHOTO_SHOOT}&size=2`);
              //dispatch(toggleContentWizard());
            }}
            title="Whatsapp (1.91:1)"
            sampleImagePath="content-photoshoot-whatsapp.png"
          />
        </Carousel>
      </div>

      {/* {items?.length > 0 && (
        <>
          <div className="mt-[50px] mb-[20px]">
            <span className="text-H6 text-black  w-72 sm:w-full truncate text-ellipsis overflow-hidden">
              My Recent Designs
            </span>
          </div>
          <div className="flex-col w-[1050px]">
            <Carousel
              draggable={false}
              slidesToShow={isMobile() ? 1 : 6}
              nextArrow={<CustomRightArrow />}
              prevArrow={<CustomLeftArrow />}
              dots={false}
              arrows={isMobile() ? true : false}
            >
              {items.map((k, index) => (
                <PreviousDesignsGridCard
                  key={index}
                  loop={k}
                  updateLoops={() => { }}
                  onSelect={(contentId) => {
                    navigate(`/app/loopdetails/` + contentId);
                  }}
                />
              ))}
            </Carousel>
          </div>
        </>
      )} */}

      {/*inspirations?.length > 0 && (
        <>
          <div className="mt-[50px] mb-[20px]">
            <text className="text-H6 text-black  w-72 sm:w-full truncate text-ellipsis overflow-hidden">
              Inspirations For Quick Start
            </text>
          </div>
          <InspirationsForQuickstartGrid
            onClick={(revisionId) => {
              if (revisionId !== "") {
                generateContentFromRevision({ revisionId: revisionId })
                  .unwrap()
                  .then((k) => {
                    const contentId = k.content_id ?? "";
                    navigate(`/app/loopdetails/` + contentId);
                  });
              }
            }}
            onRefresh={() => {
              getInspirations();
            }}
            inspirations={inspirations}
          />
        </>
      )*/}

      {/* {favoriteItems?.length > 0 && (
        <>
          <div className="mt-[50px] mb-[20px]">
            <text className="text-H6 text-black  w-72 sm:w-full truncate text-ellipsis overflow-hidden">
              My Favorites
            </text>
          </div>
          <div className="flex-col w-[1050px]">
            <Carousel
              draggable={false}
              slidesToShow={isMobile() ? 1 : 6}
              nextArrow={<CustomRightArrow />}
              prevArrow={<CustomLeftArrow />}
              dots={false}
              arrows={isMobile() ? true : false}
            >
              {favoriteItems.map((k, index) => (
                <PreviousDesignsGridCard
                  key={index}
                  loop={k}
                  updateLoops={() => { }}
                  onSelect={(contentId) => {
                    navigate(`/app/loopdetails/` + contentId);
                  }}
                />
              ))}
            </Carousel>
          </div>
        </>
      )} */}
    </div>
  );
}

export default ContentPhotoshoot;
