import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRandomInspirations: build.query<
      GetRandomInspirationsApiResponse,
      GetRandomInspirationsApiArg
    >({
      query: () => ({ url: `/inspiration` }),
    }),
    getTheme: build.query<GetThemeApiResponse, GetThemeApiArg>({
      query: (queryArg) => ({
        url: `/inspiration/theme`,
        params: { id: queryArg.id },
      }),
    }),
    getTextInspiration: build.query<
      GetTextInspirationApiResponse,
      GetTextInspirationApiArg
    >({
      query: (queryArg) => ({
        url: `/inspiration/text`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    search: build.query<SearchApiResponse, SearchApiArg>({
      query: (queryArg) => ({
        url: `/inspiration/search`,
        params: {
          return_size: queryArg.returnSize,
          search_term: queryArg.searchTerm,
          creative_only: queryArg.creativeOnly,
        },
      }),
    }),
    getImageInspiration: build.query<
      GetImageInspirationApiResponse,
      GetImageInspirationApiArg
    >({
      query: (queryArg) => ({
        url: `/inspiration/image`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    getCreativeInspiration: build.query<
      GetCreativeInspirationApiResponse,
      GetCreativeInspirationApiArg
    >({
      query: (queryArg) => ({
        url: `/inspiration/creative`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    getAdContent: build.query<GetAdContentApiResponse, GetAdContentApiArg>({
      query: (queryArg) => ({
        url: `/inspiration/adcontent`,
        params: { id: queryArg.id },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetRandomInspirationsApiResponse =
  /** status 200 OK */ InspirationResponse[];
export type GetRandomInspirationsApiArg = void;
export type GetThemeApiResponse = /** status 200 OK */ CreativeTheme;
export type GetThemeApiArg = {
  id: string;
};
export type GetTextInspirationApiResponse =
  /** status 200 OK */ InspirationResponse[];
export type GetTextInspirationApiArg = {
  page?: number;
  size?: number;
};
export type SearchApiResponse = /** status 200 OK */ InspirationResponse[];
export type SearchApiArg = {
  returnSize?: number;
  searchTerm: string;
  creativeOnly?: boolean;
};
export type GetImageInspirationApiResponse =
  /** status 200 OK */ InspirationResponse[];
export type GetImageInspirationApiArg = {
  page?: number;
  size?: number;
};
export type GetCreativeInspirationApiResponse =
  /** status 200 OK */ InspirationResponse[];
export type GetCreativeInspirationApiArg = {
  page?: number;
  size?: number;
};
export type GetAdContentApiResponse = /** status 200 OK */ AdContent;
export type GetAdContentApiArg = {
  id: string;
};
export type InspirationResponse = {
  type?: "text" | "image" | "creative";
  platform?: string;
  size?: string;
  content?: string;
  fileId?: string;
  revisionId?: string;
  adId?: string;
  subCategory?: string;
  industry?: string;
  objective?: string;
};
export type CreativeTheme = {
  id?: string;
  parentConcept?: string;
  childConcept?: string;
  subChildConcept?: string;
  recurrenceRule?: string;
  nextOccurrence?: string;
  imageId?: string;
  thumbnailId?: string;
};
export type AdContent = {
  adRootId?: string;
  adId?: string;
  adHdImage?: string;
  adImage?: string;
  brandBriefDescription?: string;
  creationTime?: string;
  callToAction?: string;
  descriptionOfVisualImagery?: string;
  header?: string;
  industryId?: string;
  mainCategory?: string;
  productBriefDescription?: string;
  subCategory?: string;
  subHeader?: string;
  subIndustryId?: string;
  userId?: string;
  liked?: boolean;
};
export const {
  useGetRandomInspirationsQuery,
  useLazyGetRandomInspirationsQuery,
  useGetThemeQuery,
  useLazyGetThemeQuery,
  useGetTextInspirationQuery,
  useLazyGetTextInspirationQuery,
  useSearchQuery,
  useLazySearchQuery,
  useGetImageInspirationQuery,
  useLazyGetImageInspirationQuery,
  useGetCreativeInspirationQuery,
  useLazyGetCreativeInspirationQuery,
  useGetAdContentQuery,
  useLazyGetAdContentQuery,
} = injectedRtkApi;
