import * as React from "react";
import type { SVGProps } from "react";
const VideoImageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
      width="40" height="40" viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M13 29C12.45 29 11.9792 28.8042 11.5875 28.4125C11.1958 28.0208 11 27.55 11 27V13C11 12.45 11.1958 11.9792 11.5875 11.5875C11.9792 11.1958 12.45 11 13 11H21C21.2833 11 21.5208 11.0958 21.7125 11.2875C21.9042 11.4792 22 11.7167 22 12C22 12.2833 21.9042 12.5208 21.7125 12.7125C21.5208 12.9042 21.2833 13 21 13H13V27H27V19C27 18.7167 27.0958 18.4792 27.2875 18.2875C27.4792 18.0958 27.7167 18 28 18C28.2833 18 28.5208 18.0958 28.7125 18.2875C28.9042 18.4792 29 18.7167 29 19V27C29 27.55 28.8042 28.0208 28.4125 28.4125C28.0208 28.8042 27.55 29 27 29H13ZM25 15H24C23.7167 15 23.4792 14.9042 23.2875 14.7125C23.0958 14.5208 23 14.2833 23 14C23 13.7167 23.0958 13.4792 23.2875 13.2875C23.4792 13.0958 23.7167 13 24 13H25V12C25 11.7167 25.0958 11.4792 25.2875 11.2875C25.4792 11.0958 25.7167 11 26 11C26.2833 11 26.5208 11.0958 26.7125 11.2875C26.9042 11.4792 27 11.7167 27 12V13H28C28.2833 13 28.5208 13.0958 28.7125 13.2875C28.9042 13.4792 29 13.7167 29 14C29 14.2833 28.9042 14.5208 28.7125 14.7125C28.5208 14.9042 28.2833 15 28 15H27V16C27 16.2833 26.9042 16.5208 26.7125 16.7125C26.5208 16.9042 26.2833 17 26 17C25.7167 17 25.4792 16.9042 25.2875 16.7125C25.0958 16.5208 25 16.2833 25 16V15ZM19.25 24L17.4 21.525C17.3 21.3917 17.1667 21.325 17 21.325C16.8333 21.325 16.7 21.3917 16.6 21.525L14.6 24.2C14.4667 24.3667 14.45 24.5417 14.55 24.725C14.65 24.9083 14.8 25 15 25H25C25.2 25 25.35 24.9083 25.45 24.725C25.55 24.5417 25.5333 24.3667 25.4 24.2L22.65 20.525C22.55 20.3917 22.4167 20.325 22.25 20.325C22.0833 20.325 21.95 20.3917 21.85 20.525L19.25 24Z"/>
  </svg>
);
export default VideoImageIcon;
