import * as React from "react";
import type { SVGProps } from "react";
const LogoFilterIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
         {...props}
    >
        <path d="M9.1665 14.1667H14.9998C15.2359 14.1667 15.4339 14.0868 15.5936 13.9271C15.7533 13.7674 15.8332 13.5695 15.8332 13.3334V10C15.8332 9.76393 15.7533 9.56601 15.5936 9.40629C15.4339 9.24657 15.2359 9.16671 14.9998 9.16671H9.1665C8.93039 9.16671 8.73248 9.24657 8.57275 9.40629C8.41303 9.56601 8.33317 9.76393 8.33317 10V13.3334C8.33317 13.5695 8.41303 13.7674 8.57275 13.9271C8.73248 14.0868 8.93039 14.1667 9.1665 14.1667ZM3.33317 16.6667C2.87484 16.6667 2.48248 16.5035 2.15609 16.1771C1.8297 15.8507 1.6665 15.4584 1.6665 15V5.00004C1.6665 4.54171 1.8297 4.14935 2.15609 3.82296C2.48248 3.49657 2.87484 3.33337 3.33317 3.33337H16.6665C17.1248 3.33337 17.5172 3.49657 17.8436 3.82296C18.17 4.14935 18.3332 4.54171 18.3332 5.00004V15C18.3332 15.4584 18.17 15.8507 17.8436 16.1771C17.5172 16.5035 17.1248 16.6667 16.6665 16.6667H3.33317ZM3.33317 15H16.6665V5.00004H3.33317V15Z"/>
    </svg>
);
export default LogoFilterIcon;
