import ContentContainer from "../../components/ContentContainer";
import Icon from "../../components/Icon";
import { Mediums } from "../../constants/Mediums";

import { useEffect, useState } from "react";
import { t } from "i18next";
import IconButton from "../../components/IconButton";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/PrimaryButton";
import { useAppDispatch } from "../../helpers/hooks";
import {
  useDeleteContentMutation,
  useEditNameMutation,
} from "../../services/ContentApi.old/ContentApi";
import {
  useEditTextMutation,
  useFavoriteMutation,
} from "../../services/TextApi.old/TextApi";
import { setDialog, toggleDialog } from "../../store/appSlice";
import ContentImage from "../../components/ContentImage";
import { useFavoriteImageMutation } from "../../services/ImageApi.old/ImageApi";
import { Liked, Like, Copy, Delete } from "../../assets/IconComponents";
import {
  useFavorite1Mutation,
} from "../../services/ContentApi";
import SvgEyeIcon from "../../assets/IconComponents/EyeIcon";
import { useWindowSize } from "@uidotdev/usehooks";
import { Project, useDeleteProjectMutation } from "../../services/ProjectApi";
import SvgAIProductPhotoshootHeaderLogo from "../../assets/IconComponents/AIProductPhotoshootHeaderLogo";

export interface IProjectGridCardProps {
  project: Project;
  updateProjects: () => void;
  onSelect: (projectId: string) => void;
}

function ProjectGridCard(props: IProjectGridCardProps) {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [updateText, { isSuccess: textUpdated }] = useEditTextMutation();
  const [editName, { isSuccess: nameUpdated }] = useEditNameMutation();

  const [deleteProject, { isSuccess: projectDeleted }] =
    useDeleteProjectMutation();
  const [setTextFavorite, { isSuccess: textFavoriteUpdated }] =
    useFavoriteMutation();
  const [setImageFavorite, { isSuccess: imageFavoriteUpdated }] =
    useFavoriteImageMutation();
  const [updateFav, { isSuccess: favUpdated }] = useFavorite1Mutation();

  const [isFavori, setFavori] = useState(false);
  const [showCopyRevision, setShowCopyRevision] = useState(false);
  const size = useWindowSize();
  const [videoType, setVideoType] = useState<"Gif" | "Video">("Video");

  const getTruncatedText = (text: string) => {
    let truncationLimit = 40;
    if (size.width && size.width > 1350 && size.width < 1600)
      truncationLimit = 30;
    else if (size.width && size.width > 580 && size.width <= 1350)
      truncationLimit = 20;
    return `${(text.length ?? 0) > truncationLimit
      ? text.slice(0, truncationLimit) + "..."
      : text
      }`;
  };

  useEffect(() => {
    if (
      textUpdated ||
      nameUpdated ||
      textFavoriteUpdated ||
      imageFavoriteUpdated ||
      projectDeleted
    ) {
      props.updateProjects();
    }
  }, [textUpdated, nameUpdated, projectDeleted]);

  // useEffect(() => {
  //   setFavori(
  //     (props.loop.content?.is_favorite ?? false) ||
  //     (props.loop.revision?.is_favorite ?? false)
  //   );
  // }, [cardContent]);

  const getMediaIcon = (mediaName: string) => {
    const platformName = mediaName.split(" | ")[0];
    const icon = Mediums.find((k) => k.name === platformName)?.iconColor;
    return icon;
  };

  // const updateFavorite = () => {
  //   updateFav({
  //     contentId: props.loop.revision?.id?.content_id ?? "",
  //     isFavorite: !isFavori ?? false,
  //   });
  //   setFavori(!isFavori);
  // };

  const showDeleteDialog = () => {
    dispatch(
      setDialog({
        actions: (
          <>
            <div className="flex w-full flex-col lg:flex-row">
              <PrimaryButton
                size="middle"
                className=""
                noFill
                title={t("cancel")}
                onClick={() => {
                  dispatch(toggleDialog(false));
                }}
              />
              <PrimaryButton
                size="middle"
                className="ml-2"
                title={t("yes-delete-loop")}
                onClick={() => {
                  dispatch(toggleDialog(false));
                  deleteRevision();
                }}
              />
            </div>{" "}
          </>
        ),
        title: t("delete"),
        message: t("are-you-sure-you-would-like-to-delete-content"),
        onClose: () => {
          dispatch(toggleDialog(false));
        },
      })
    );
    dispatch(toggleDialog(true));
  };

  const deleteRevision = () => {
    deleteProject({
      projectId: props.project?.id?.project_id ?? "",
    });
  };


  return (
    <ContentContainer
      className={`p-[18px] h-[310px] cursor-pointer`}
      onClick={() => {
        props.onSelect(props.project.id?.project_id ?? "");
      }}
      hoverable
      onHover={() => setHover(true)}
      onBlur={() => setHover(false)}
    >
      <div className={`flex w-full flex-col items-start gap-[10px]`}>
        <div className={`flex flex-row items-center justify-between w-full`}>
          <div className="h-5 justify-center items-center gap-1 flex flex-row">
            <Icon
              component={SvgAIProductPhotoshootHeaderLogo}
              width="20"
              height="20"
            />
            <span className="text-H6 text-black">
              {getTruncatedText(props.project?.name ?? "")}
            </span>
          </div>
          <div className="flex flex-row gap-1">
            {props.project?.read_only && <SvgEyeIcon />}
            {props.project?.read_only === false && (
              <IconButton
                className="hover:w-20"
                title={isFavori ? t("unlike") : t("like")}
                size={"18"}
                color={isFavori ? "red" : "gray"}
                icon={isFavori ? Liked : Like}
                onClick={(e) => {
                  e.stopPropagation();
                  //updateFavorite();
                }}
              />
            )}
          </div>
        </div>

        {
          <ContentImage
            id={props.project?.generatedContent ?? ""}
            className="py-1 h-[210px] bg-bg w-full rounded-[8px] object-contain"
          />
        }



        {props.project?.read_only === false && (
          <div
            className={`flex w-full h-[20px] bg-white justify-end items-center`}
          >
            <div className="flex flex-row justify-end items-center">
              {/* <div className="ml-3">
                <IconButton
                  color="#000000"
                  className="hover:w-16"
                  title={t("copy")}
                  icon={Copy}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowCopyRevision(true);
                  }}
                />
              </div> */}
              {/* <div className="ml-3">
                                    <IconButton className="hover:w-28" title={t('download')} icon={Download} onClick={() => { }} />
                                </div> */}
              <div className="ml-3">
                <IconButton
                  color="#000000"
                  className="hover:w-20"
                  title={t("delete")}
                  icon={Delete}
                  onClick={(e) => {
                    e.stopPropagation();
                    showDeleteDialog();
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {/* {showCopyRevision && (
          <CopyLoop
            contentId={props.loop.content?.id?.content_id}
            onClose={() => {
              setShowCopyRevision(false);
            }}
            onCopy={() => {
              setShowCopyRevision(false);
            }}
          />
        )} */}
      </div>
    </ContentContainer>
  );
}

export default ProjectGridCard;
