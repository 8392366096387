import { t } from "i18next";
import { FunctionComponent, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { toggleLoading } from "../../../store/appSlice";
import { useLazyGetAudienceQuery } from "../../../services/AudiencetApi";
import SvgConnectBrandChannelsIcon from "../../../assets/IconComponents/ConnectBrandChannelsIcon";
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import BrandColorPicker from "../../../components/ColorPicker/BrandColorPicker";
import SvgBrandColorsPaletteIcon from "../../../assets/IconComponents/BrandCollorsPaletteIcon";
import SvgCompanyMaterialsIcon from "../../../assets/IconComponents/BrandCompanyMaterialsIcon";
import SvgBrandFontsIcon from "../../../assets/IconComponents/BrandFontsIcon";
import FontSelector from "../../../components/FontSelector/FontSelector";
import { useLazyListFontsQuery } from "../../../services/FontApi";
import { setNewBrandInfo } from "../../../store/brandSlice";
import SvgBrandTechnicalDocsIcon from "../../../assets/IconComponents/BrandTechnicalDocsIcon";
import UploadCompanyMaterialNew from "../../../components/UploadFile/UploadCompanyMaterialNew";
import SvgBrandHubBrandLogosIcon from "../../../assets/IconComponents/BrandHubBrandLogosIcon";
import BrandHubImportGuidelines from "./BrandHubImportGuidelines";
import UploadFileInfinite from "../../../components/UploadFile/UploadFileInfinite";


interface IBrandHubBrandIdentityProps {
  className?: string;
}

const BrandHubBrandIdentity: FunctionComponent<IBrandHubBrandIdentityProps> = (
  props: IBrandHubBrandIdentityProps
) => {
  const [colors, setColors] = useState({
    color1: "",
    color2: "",
    color3: "",
    color4: "",
    color5: "",
  });
  const brandInfo = useAppSelector((k) => k.brand.newBrand);
  const dispatch = useAppDispatch();
  const [getFonts, { data: fontsToShow }] = useLazyListFontsQuery();
  const [
    getAudiencesList,
    { isLoading: audiencesListLoading, data: audiencesListApi },
  ] = useLazyGetAudienceQuery();

  useEffect(() => {
    let c = Object.values(colors);
    dispatch(setNewBrandInfo({ ...brandInfo, brandColors: c }));
  }, [colors]);
  const getColor = (at: number) => {
    if (brandInfo.brandColors && brandInfo.brandColors) {
      let colors = brandInfo.brandColors;
      if (colors.length > at) {
        return colors[at];
      }
    }

    return undefined;
  };

  useEffect(() => {
    getFonts();
  }, []);
  useEffect(() => {
    dispatch(toggleLoading(audiencesListLoading));
  }, [audiencesListLoading]);
  return (
    <div className="flex flex-col w-full items-center gap-3">
      {/*
      <BrandHubImportGuidelines />
      */}

      <div className="flex flex-col bg-white border-1 rounded-[8px] pb-5 w-full">
        <div className="mt-2 ml-2 flex flex-row w-full items-center">
          <SvgBrandHubBrandLogosIcon />
          <span className="text-H7 text-black ml-2 w-72 sm:w-full truncate text-ellipsis overflow-hidden">
            {t("brand-logos")}
          </span>
        </div>

        <div className="flex flex-col w-full md:flex-row px-2 items-center mt-3">

          <UploadFileInfinite
            size="library"
            maxFileCount={4}
            uploadedFiles={brandInfo.selectedCompanyLogos}
            title="Add up to 4 Logo Files (Ex. Light and Dark Logos)"
            description="Upload .jpg or .png files. Max 25mb."
            filesChanged={(f) => {
              {
                dispatch(
                  setNewBrandInfo({
                    ...brandInfo,
                    selectedCompanyLogos: f.map((k: any) => ({
                      id: k.uploadedId,
                      name: k.name,
                      uploaded: true,
                    })),
                  })
                );
              }
            }}
            selectionEnabled={false}
            showDeleteButton={true}
            showRemoveBgButton={true}
            showRefreshButton={true} />
        </div>
      </div>
      <div className=" grid grid-cols-2 w-full gap-3">
        <div className="flex flex-col bg-white border-1 rounded-[8px] pb-5">
          <div className="mt-2 ml-2 flex flex-row w-full items-center">
            <SvgBrandColorsPaletteIcon />
            <span className="text-H7 text-black ml-2 w-72 sm:w-full truncate text-ellipsis overflow-hidden">
              {t("brand-colors")}
            </span>
          </div>
          <div className="flex flex-col w-full gap-6 h-[40px] items-center pt-6 px-5">
            <BrandColorPicker
              title={t("main-color") ?? ""}
              popoverClassName="max-md:ml-[150px]"
              className="h-[36px] w-[36px]"
              selectedColor={getColor(0)}
              colorSelected={(e) => {
                setColors((k) => ({ ...k, color1: e ?? "" }));
              }}
            />
            <BrandColorPicker
              title={t("secondary-color") ?? ""}
              popoverClassName="max-md:ml-[150px]"
              className="h-[36px] w-[36px]"
              selectedColor={getColor(1)}
              colorSelected={(e) => {
                setColors((k) => ({ ...k, color2: e ?? "" }));
              }}
            />
            <BrandColorPicker
              title={t("subheading-color") ?? ""}
              popoverClassName="max-md:ml-[150px]"
              className="h-[36px] w-[36px]"
              selectedColor={getColor(2)}
              colorSelected={(e) => {
                setColors((k) => ({ ...k, color3: e ?? "" }));
              }}
            />
          </div>
        </div>
        <div className="bg-white border-1 rounded-[8px] flex flex-col w-full">
          <div className="mt-2 ml-2 flex flex-row w-full items-center">
            <SvgBrandFontsIcon />
            <span className="text-H7 text-black ml-2 w-72 sm:w-full truncate text-ellipsis overflow-hidden">
              {t("brand-fonts")}
            </span>
          </div>
          <div className="flex flex-col mt-[20px] px-5 mb-3">
            <FontSelector
              selectedFont={brandInfo.selectedCompanyPrimaryFont}
              fonts={fontsToShow ?? []}
              title={t("primary-font")}
              fontSelected={(e) => {
                console.log("Primary Font is", e);
                dispatch(
                  setNewBrandInfo({
                    ...brandInfo,
                    selectedCompanyPrimaryFont: e,
                  })
                );
              }}
            />
            <FontSelector
              selectedFont={brandInfo.selectedCompanySecondaryFont}
              fonts={fontsToShow ?? []}
              title={t("secondary-font")}
              className="mt-[20px]"
              fontSelected={(e) => {
                dispatch(
                  setNewBrandInfo({
                    ...brandInfo,
                    selectedCompanySecondaryFont: e,
                  })
                );
              }}
            />
            <FontSelector
              selectedFont={brandInfo.selectedCompanyAccentFont}
              fonts={fontsToShow ?? []}
              title={t("accent-font")}
              className="mt-[20px]"
              fontSelected={(e) => {
                dispatch(
                  setNewBrandInfo({
                    ...brandInfo,
                    selectedCompanyAccentFont: e,
                  })
                );
              }}
            />
          </div>
        </div>
      </div>
      {/*<div className="flex flex-row grid grid-cols-2 w-full gap-3">
        <div className="flex flex-col bg-white border-1 rounded-[8px] pb-5 w-full">
          <div className="mt-2 ml-2 flex flex-row w-full items-center">
            <SvgCompanyMaterialsIcon />
            <span className="text-H7 text-black ml-2 w-72 sm:w-full truncate text-ellipsis overflow-hidden">
              {t("company-materials")}
            </span>
          </div>
          <UploadCompanyMaterialNew
            uploadedFiles={brandInfo.selectedCompanyFiles}
            title=""
            tooltip="Delete Material"
            description="Upload .doc or .pdf Ex. Deck, Business Plan."
            className="md:w-full ml-3"
            filesChanged={(f) => {
              console.log("Files are", f);
              dispatch(
                setNewBrandInfo({
                  ...brandInfo,
                  selectedCompanyFiles: f.map((k: any) => ({
                    id: k.uploadedId,
                    name: k.name,
                    uploaded: true,
                  })),
                })
              );
            }}
          />
        </div>
        <div className="flex flex-col bg-white border-1 rounded-[8px] pb-5">
          <div className="mt-2 ml-2 flex flex-row w-full items-center">
            <SvgBrandTechnicalDocsIcon />
            <span className="text-H7 text-black ml-2 w-72 sm:w-full truncate text-ellipsis overflow-hidden">
              {t("technical-documents")}
            </span>
          </div>
          <UploadCompanyMaterialNew
            uploadedFiles={brandInfo.technicalLinks}
            title=""
            tooltip="Delete Document"
            description="Upload .doc or .pdf Ex. Deck, Business Plan."
            className="md:w-full ml-3"
            filesChanged={(f) => {
              dispatch(
                setNewBrandInfo({
                  ...brandInfo,
                  technicalLinks: f.map((k: any) => ({
                    id: k.uploadedId,
                    name: k.name,
                    uploaded: true,
                  })),
                })
              );
            }}
          />
        </div>
          </div>*/}
    </div>
  );
};

export default BrandHubBrandIdentity;
