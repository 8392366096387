import { FunctionComponent, useEffect, useState } from "react";
import SvgUploadIcon from "../../assets/IconComponents/UploadIcon";
import { Accept, useDropzone } from "react-dropzone";
import { useAppDispatch } from "../../helpers/hooks";
import { toggleLoading } from "../../store/appSlice";
import {
  useDeleteFileDeleteByIdMutation,
  useLazyDownloadQuery,
  useUploadMutation,
} from "../../services/FileApi";
import { blobToDataURL, dataURLToBlob } from "blob-util";
import { FileProps } from "../../services/BrandApi";
import { useRemoveBackgroundMutation } from "../../services/ImageopsApi";
import PrimaryButton from "../PrimaryButton";
import { Delete } from "../../assets/IconComponents";
import { Refresh } from "../../assets/IconComponents";
import { useRef } from "react";
import borderSvg from "../../assets/icons/dashed-border.svg";
import borderSvgRed from "../../assets/icons/dashed-border-red.svg";
import { useAppSelector } from "../../helpers/hooks";
import SvgCheckers from "../../assets/IconComponents/Checkers";

interface UploadFileProps {
  title: string;
  description: string;
  className?: string;
  maxFileCount?: number;
  acceptedFileTypes?: Accept;
  maxFileSize?: number;
  disabled?: boolean;
  filesChanged: (filesIds: File[]) => void;
  selectionChanged?: (selectedFiles: FileProps[]) => void;
  uploadedFiles?: FileProps[];
  size?: "large" | "small" | "library" | "brief" | "creative_small" | "creative_xsmall" | "video_reference";
  selectionEnabled: boolean;
  maxSelectionSize?: number;
  selectedFileIds?: string[];
  maxThumbnailCount?: number;
  addNewFileToFront?: boolean;
  uploadDisabled?: boolean;
  automaticallySelectUploadedFile?: boolean;
  showRefreshButton: boolean;
  showDeleteButton: boolean;
  showRemoveBgButton: boolean;
  backgroundRemoved?: (string) => void;
}

const UploadFileInfinite: FunctionComponent<UploadFileProps> = (
  props: UploadFileProps
) => {
  const refreshInputRef = useRef<HTMLInputElement | null>(null);
  const buttonClickRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState("");
  const brandInfo = useAppSelector((k) => k.brand.newBrand);
  const [selectedFiles, setFiles] = useState<any[]>([]);
  const [pickedFiles, setPickedFiles] = useState<FileProps[]>([]);
  const [fileToRefresh, setFileToRefresh] = useState<number>(-1);
  const [thumbSize, setThumbSize] = useState<string>("180px");
  const [gridColSize, setGridColSize] = useState<string>("grid-cols-4")
  const [downloadedBefore, setDownloadedBefore] = useState<boolean>(false);

  const [fileIndexToRemoveBackground, setFileIndexToRemoveBackground] =
    useState<number>(-1);
  const [lastFileIndexToRemoveBackground, setLastFileIndexToRemoveBackground] =
    useState<number>(-1);
  const dispatch = useAppDispatch();
  const [
    uploadFiles,
    {
      data: uploadedFileData,
      isSuccess: filesUploaded,
      isLoading: filesUploading,
    },
  ] = useUploadMutation();
  const [
    deleteFile,
    { data: fileDeletedData, isSuccess: fileDeleted, isLoading: fileDeleting },
  ] = useDeleteFileDeleteByIdMutation();
  const [
    downloadFile,
    {
      data: fileDownloaddData,
      isSuccess: fileDownloaded,
      isLoading: fileDownloading,
      currentData: dt,
    },
  ] = useLazyDownloadQuery();

  const [
    removeBackground,
    { data: backgroundRemovedImage, isLoading: backgroundRemovalInProgress },
  ] = useRemoveBackgroundMutation();

  const [errorIndex, setErrorIndex] = useState(-1);

  useEffect(() => {
    if (!downloadedBefore) {
      if (props.uploadedFiles && props.uploadedFiles.length > 0) {
        Promise.all(
          props.uploadedFiles?.map((k) => downloadFileFrom(k.id ?? ""))
        ).then((k) => {
          setFiles(k);
          setDownloadedBefore(true);
        });
      }
    }

  }, [props.uploadedFiles]);



  useEffect(() => {
    if (error !== "") {
      setTimeout(() => {
        setError("");
        setErrorIndex(-1);
      }, 3000);
    }
  }, [error]);

  useEffect(() => {
    if (backgroundRemovedImage?.backgroundRemovedFile?.id) {
      if (fileIndexToRemoveBackground > -1) {
        const backgroundRemovedFile =
          backgroundRemovedImage?.backgroundRemovedFile;
        Promise.all([downloadFileFrom(backgroundRemovedFile.id ?? "")]).then(
          (fileProps) => {
            let selectedFilesCopy = [...selectedFiles];
            let prevFile = selectedFiles[fileIndexToRemoveBackground];
            selectedFilesCopy[fileIndexToRemoveBackground] = fileProps[0];
            setFiles(selectedFilesCopy);
            setFileIndexToRemoveBackground(-1);
            if (props.backgroundRemoved) {
              props.backgroundRemoved(backgroundRemovedFile.id ?? "");
            }
            props.filesChanged(selectedFilesCopy);
            if (
              pickedFiles?.some(
                (k) => k.id === prevFile.uploadedId
              )
            ) {
              let updatedPickedFiles = [
                ...(pickedFiles.filter((k) => k.id !== prevFile.uploadedId)),
                {
                  id: fileProps[0].uploadedId,
                  name: fileProps[0].name,
                  uploaded: true,
                },
              ];
              setPickedFiles(updatedPickedFiles);
              props.selectionChanged &&
                props.selectionChanged(updatedPickedFiles);
            }
          }
        );
      }
    }
  }, [backgroundRemovedImage]);

  useEffect(() => {
    dispatch(toggleLoading(backgroundRemovalInProgress));
  }, [backgroundRemovalInProgress]);


  useEffect(() => {
    if (props.size === "large") {
      setThumbSize("180px");
    } else if (props.size === "small") {
      setThumbSize("115px");
    } else if (props.size === "library") {
      setThumbSize("140px");
    } else if (props.size === "brief") {
      setThumbSize("100px");
    } else if (props.size === "creative_small") {
      setThumbSize("115px");
    } else if (props.size === "creative_xsmall") {
      setGridColSize("100px");
    } else if (props.size === "video_reference") {
      setThumbSize("120px");
    }
  }, [props.size])

  useEffect(() => {
    if (props.size === "large") {
      setGridColSize("grid-cols-4")
    } else if (props.size === "small") {
      setGridColSize("grid-cols-3")
    } else if (props.size === "library") {
      setGridColSize("grid-cols-5")
    } else if (props.size === "brief") {
      setGridColSize("grid-cols-3")
    } else if (props.size === "creative_small") {
      setGridColSize("grid-cols-5")
    }
    else if (props.size === "creative_xsmall") {
      setGridColSize("grid-cols-4");
    } else if (props.size === "video_reference") {
      setGridColSize("grid-cols-6")
    }
  }, [props.size])

  useEffect(() => {
    const newPickedFiles = [...props.uploadedFiles?.filter(f => props.selectedFileIds?.some(k => k === f.id) ?? false) ?? []]
    setPickedFiles(newPickedFiles)
  }, [props.selectedFileIds, props.uploadedFiles])

  const downloadFileFrom = async (fileId: string) => {
    return new Promise<any>((resolve) => {
      downloadFile({
        id: fileId,
      })
        .unwrap()
        .then(async (k) => {
          const item = {
            uploadedId: fileId,
            name: k.name,
            type: k.file.type.includes("ima") ? "image" : "other",
            preview: await blobToDataURL(k.file),
          };

          return resolve(item);
        })
        .catch((e) => {
          console.log("error", e);
        });
    });
  };

  const handleRefreshButtonClick = (index: number) => {
    buttonClickRef.current?.click();
  };

  const getInnerThumbSize = (thumbSize: string) => {
    if (thumbSize == "180px") {
      return "178px";
    } else if (thumbSize == "115px") {
      return "113px";
    } else if (thumbSize == "140px") {
      return "138px";
    } else if (thumbSize == "100px") {
      return "98px";
    } else if (thumbSize == "120px") {
      return "118px";
    }
  }


  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxSize: props.maxFileSize ?? 25000000,
    maxFiles: 1,
    accept: props.acceptedFileTypes ?? {
      "image/png": [".png", ".jpg", ".jpeg"]
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length === 0) {
        return;
      }
      const files = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      if (rejectedFiles.length > 0) {
        let message = rejectedFiles[0].errors[0].message;
        setError(message);
        return;
      }

      selectFile(files, fileToRefresh);
    },
    onFileDialogOpen() {
      console.log("file dialog");
      setError("");
    },
  });

  const fileToBlob = (file?: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (!!reader.result) {
          resolve(reader.result);
        } else {
          reject(Error("Failed converting to base64"));
        }
      };
    });
  };

  const selectFile = async (files: any[], index: number) => {
    if (selectedFiles.length < (props.maxFileCount ?? 1242151) || index !== -1) {
      dispatch(toggleLoading(true));
      let selectedFile = files[0];
      let a = (await fileToBlob(selectedFile)) as string;
      let blob = dataURLToBlob(a);
      const formData: FormData = new FormData();
      formData.append("files", blob, selectedFile.name);
      uploadFiles({
        body: formData,
      })
        .unwrap()
        .then((k) => {
          selectedFile["uploadedId"] = k.ids ?? "";
          console.log("success", k);
          if (index !== -1) {
            const toBeDeleted = selectedFiles[index];

            let selectedFilesCopy = [...selectedFiles]
            selectedFilesCopy[index] = selectedFile;

            setFiles(selectedFilesCopy);
            props.filesChanged(selectedFilesCopy);

          } else {
            if (!props.addNewFileToFront) {
              setFiles((selectedFiles) => [...selectedFiles, selectedFile]);
              props.filesChanged([...selectedFiles, selectedFile]);
            }
            else {
              setFiles((selectedFiles) => [selectedFile, ...selectedFiles]);
              props.filesChanged([selectedFile, ...selectedFiles]);
            }
          }
          if (props.automaticallySelectUploadedFile) {
            if (pickedFiles.length === props.maxSelectionSize) {
              let updatedPickedFiles = [
                ...pickedFiles.slice(1),
                {
                  id: selectedFile.uploadedId,
                  name: selectedFile.name,
                  uploaded: true,
                },
              ];
              setPickedFiles(updatedPickedFiles);
              props.selectionChanged &&
                props.selectionChanged(updatedPickedFiles);
            } else {
              let updatedPickedFiles = [
                ...pickedFiles,
                {
                  id: selectedFile.uploadedId,
                  name: selectedFile.name,
                  uploaded: true,
                },
              ];
              setPickedFiles(updatedPickedFiles);
              props.selectionChanged &&
                props.selectionChanged(updatedPickedFiles);
            }
          }

          dispatch(toggleLoading(false));
          setFileToRefresh(-1);
        });
    }
    else {
      setError(
        `Selected files should be maximum ${props.maxFileCount ?? 2} items`
      );
    }


  };

  const thumbs = selectedFiles
    .slice(
      0,
      props.maxThumbnailCount ? props.maxThumbnailCount : selectedFiles.length
    )
    .map((file, index) => (
      <>
        {file && (
          <div
            key={file.name}
            style={{
              backgroundImage: `url(${borderSvg})`, // Set the SVG file as the background image
              backgroundSize: "cover", // Adjust the sizing as needed
              backgroundRepeat: "no-repeat", // Prevent the SVG from repeating
            }}
            className={`relative flex  cursor-pointer rounded-[8px] h-[${thumbSize}] w-[${thumbSize}] md:w-[${thumbSize}] justify-center ${pickedFiles.filter((pf) => pf.id === file.uploadedId).length !== 0
              ? `border-1 border-red`
              : ``
              }`}
            onClick={() => {
              if (props.selectionEnabled) {
                if (
                  pickedFiles.filter((pf) => pf.id === file.uploadedId)
                    .length === 0
                ) {
                  if (pickedFiles.length === props.maxSelectionSize) {
                    let updatedPickedFiles = [
                      ...pickedFiles.slice(1),
                      { id: file.uploadedId, name: file.name, uploaded: true },
                    ];
                    setPickedFiles(updatedPickedFiles);
                    props.selectionChanged &&
                      props.selectionChanged(updatedPickedFiles);
                  } else {
                    let updatedPickedFiles = [
                      ...pickedFiles,
                      { id: file.uploadedId, name: file.name, uploaded: true },
                    ];
                    setPickedFiles(updatedPickedFiles);
                    props.selectionChanged &&
                      props.selectionChanged(updatedPickedFiles);
                  }
                } else {
                  let newPickedFiles = pickedFiles.filter(
                    (pf) => pf.id !== file.uploadedId
                  );
                  setPickedFiles(newPickedFiles);
                  props.selectionChanged &&
                    props.selectionChanged(newPickedFiles);
                }
              }
            }}
          >
            {(props.size === "large" || props.size === "library") && (
              <div className="flex flex-row gap-1 h-10 absolute -bottom-5 m-3">
                {(props.showRefreshButton &&
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} ref={refreshInputRef} />

                    <PrimaryButton
                      icon={<Refresh fill={"#121315"} />}
                      noFill
                      className="bg-bg border-1 border-border "
                      size="small"
                      isDisabled={false}
                      tooltip="Change Visual"
                      onClick={() => {
                        setFileToRefresh(index);
                        handleRefreshButtonClick(index);
                      }}
                    />
                  </div>
                )}


                {(props.showDeleteButton &&
                  < PrimaryButton
                    icon={<Delete fill={"#121315"} />}
                    noFill
                    className="bg-bg border-1 border-border "
                    size="small"
                    isDisabled={false}
                    tooltip="Delete Visual"
                    onClick={() => {
                      //e.stopPropagation();
                      const id = file.uploadedId;

                      setFiles(
                        selectedFiles.filter(
                          (k) => k.uploadedId !== file.uploadedId
                        )
                      );
                      props.filesChanged(
                        selectedFiles.filter(
                          (k) => k.uploadedId !== file.uploadedId
                        )
                      );
                      deleteFile({
                        id: id,
                      });
                    }}
                  />
                )}

                {(props.showRemoveBgButton) && (
                  <PrimaryButton
                    icon={<SvgCheckers fill={"#121315"} />}
                    noFill
                    className="bg-bg border-1 border-border "
                    size="small"
                    isDisabled={false}
                    tooltip="Remove Background"
                    onClick={() => {
                      //e.stopPropagation();
                      const id = file.uploadedId;
                      setFileIndexToRemoveBackground(index);
                      setLastFileIndexToRemoveBackground(index);
                      removeBackground({ removeBackgroundRequest: { imageFileId: id } });
                    }}
                  />
                )}
              </div>
            )}
            {(props.showRemoveBgButton) &&
              (props.size === "small" ||
                props.size === "creative_small" ||
                props.size === "brief" ||
                props.size === "video_reference") && (
                <div className="flex absolute top-2 right-2">
                  <PrimaryButton
                    icon={<SvgCheckers fill={"#121315"} />}
                    noFill
                    className=" bg-bg border-1 border-border "
                    size={"small"}
                    isDisabled={false}
                    tooltip="Remove Background"
                    onClick={() => {
                      //e.stopPropagation();
                      const id = file.uploadedId;
                      setFileIndexToRemoveBackground(index);
                      setLastFileIndexToRemoveBackground(index);
                      removeBackground({ removeBackgroundRequest: { imageFileId: id } });
                    }}
                  />
                </div>
              )}

            <img
              src={file.preview}
              alt={file.name}
              style={{
                marginTop: "",
                width: `${getInnerThumbSize(thumbSize)}`,
                height: `${getInnerThumbSize(thumbSize)}`,
                borderRadius: 10,
                backgroundColor: "rgb(234 235 230)",
              }}
              className="object-contain"
            />
          </div>
        )}
      </>
    ));

  return (
    <>
      <div
        className={`relative flex flex-col w-full mb-2 ${props.disabled ? "p-3" : ""
          } ${props.className}`}
        style={{ pointerEvents: "all" }}
      >
        <div className={`grid ${gridColSize} items-center gap-2`}>
          {error && !props.uploadDisabled && (
            <div
              key={`upload_with_error`}
              style={{
                backgroundImage: `url(${borderSvgRed})`, // Set the SVG file as the background image
                backgroundSize: "cover", // Adjust the sizing as needed
                backgroundRepeat: "no-repeat", // Prevent the SVG from repeating
              }}
              className={`flex cursor-pointer flex-col justify-center items-center h-[${thumbSize}] w-[${thumbSize}]  md:w-[${thumbSize}}] rounded-[8px]`}
              onClick={() => { }}
            >
              <input {...getInputProps()} ref={buttonClickRef} />
              <SvgUploadIcon fill="#FF5555" />
              <p className="text-BodySmall text-red mt-2 text-center">
                {props.description}
              </p>
            </div>
          )}
          {!error && !props.uploadDisabled && (
            <div
              key={`upload_without_error`}
              style={{
                backgroundImage: `url(${borderSvg})`, // Set the SVG file as the background image
                backgroundSize: "cover", // Adjust the sizing as needed
                backgroundRepeat: "no-repeat", // Prevent the SVG from repeating
              }}
              className={`flex cursor-pointer flex-col justify-center items-center h-[${thumbSize}] w-[${thumbSize}] md:w-[${thumbSize}] rounded-[8px]`}
              onClick={() => {
                setErrorIndex(0);
                handleRefreshButtonClick(-1);
              }}
            >
              <input {...getInputProps()} ref={buttonClickRef} />
              <SvgUploadIcon />
              <p className="text-BodySmall text-gray mt-2 text-center">
                {props.description}
              </p>
            </div>
          )}
          {thumbs}
        </div>
      </div >
    </>
  );
};

export default UploadFileInfinite;
