import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProjectById: build.query<
      GetProjectByIdApiResponse,
      GetProjectByIdApiArg
    >({
      query: (queryArg) => ({ url: `/project/${queryArg.projectId}` }),
    }),
    updateProject: build.mutation<
      UpdateProjectApiResponse,
      UpdateProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectId}`,
        method: "PUT",
        body: queryArg.project,
      }),
    }),
    deleteProject: build.mutation<
      DeleteProjectApiResponse,
      DeleteProjectApiArg
    >({
      query: (queryArg) => ({
        url: `/project/${queryArg.projectId}`,
        method: "DELETE",
      }),
    }),
    addProject: build.mutation<AddProjectApiResponse, AddProjectApiArg>({
      query: (queryArg) => ({
        url: `/project`,
        method: "POST",
        body: queryArg.project,
      }),
    }),
    listProjects: build.mutation<ListProjectsApiResponse, ListProjectsApiArg>({
      query: (queryArg) => ({
        url: `/project/list`,
        method: "POST",
        body: queryArg.listProjectRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetProjectByIdApiResponse = /** status 200 OK */ Project;
export type GetProjectByIdApiArg = {
  projectId: string;
};
export type UpdateProjectApiResponse = /** status 200 OK */ Project;
export type UpdateProjectApiArg = {
  projectId: string;
  project: Project;
};
export type DeleteProjectApiResponse = unknown;
export type DeleteProjectApiArg = {
  projectId: string;
};
export type AddProjectApiResponse = /** status 200 OK */ Project;
export type AddProjectApiArg = {
  project: Project;
};
export type ListProjectsApiResponse = /** status 200 OK */ Project[];
export type ListProjectsApiArg = {
  listProjectRequest: ListProjectRequest;
};
export type Id = {
  organization_id?: string;
  project_id?: string;
};
export type Project = {
  id?: Id;
  name?: string;
  bannerContentId?: string;
  photoshootContentId?: string;
  videoContentId?: string;
  canvas?: string;
  generatedContent?: string;
  creativeContentId?: string;
  created_date?: string;
  created_user?: string;
  updated_date?: string;
  updated_user?: string;
  brand_id?: string;
  read_only?: boolean;
};
export type ListProjectRequest = {
  page: number;
  size: number;
  brand_id?: string;
};
export const {
  useGetProjectByIdQuery,
  useLazyGetProjectByIdQuery,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useAddProjectMutation,
  useListProjectsMutation,
} = injectedRtkApi;
