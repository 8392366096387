import { useEffect, useState } from "react";
import { Tooltip } from "antd";
import SvgTextToSpeechMenuCollapseButton from "../../assets/IconComponents/TextToSpeechMenuCollapseButton";
import SvgLeftMenuTextToSpeechButton from "../../assets/IconComponents/LeftMenuTextToSpeechButton";
import SvgLeftMenuBackgroundRemoverButton from "../../assets/IconComponents/LeftMenuBackgroundRemoverButton";
import SvgAIMarketingToolsCollapsedIcon from "../../assets/IconComponents/AIMarketingToolsCollapsedIcon";
import { useAppSelector } from "../../helpers/hooks";

export enum ButtonState {
  normal,
  focus,
  hover,
}

export interface IAIMarketingToolsSubMenuProps {
  className?: string;
  deactiveColor?: string;
  state?: ButtonState;
  textColor?: string;
  collapsedMode?: boolean;
  tooltip?: string;
  onUncollapse: () => void;
  onSubItemClick: (type: string) => void;
}

function AIMarketingToolsSubMenu(props: IAIMarketingToolsSubMenuProps) {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const activePage = useAppSelector((state) => state.app.activePage);

  useEffect(() => {
    if (activePage) {
      console.log("Active Page is: ", activePage);
    }
  }, [activePage]);

  return (
    <>
      <div
        onClick={(e) => {
          //setLocalState(ButtonState.focus)
          if (props.collapsedMode ?? false) {
            props.onUncollapse();
            setCollapsed(false);
          }
        }}
        className={`${props.collapsedMode ?? false ? "" : ""} `}
      >
        {!(props.collapsedMode ?? false) && (
          <div
            className={`
            bg-menuBg cursor-pointer rounded
            `}
            title={"AI Marketing Tools"}
          >
            <div className="flex-col">
              <div
                onClick={() => {
                  const prevCollapsed = collapsed;
                  setCollapsed(!prevCollapsed);
                }}
                className={`flex flex-row items-center relative  hover:bg-menuBrandHoverBg h-[38px] rounded
                  ${collapsed ? "pl-1" : "pl-3"
                  }`}
              >
                <div
                  className={`transition-all transform-gpu ${collapsed ? "rotate-180 mb-2" : "rotate-0 "
                    }`}
                >
                  <SvgTextToSpeechMenuCollapseButton fill={"#000"} className="w-5 h-5" />
                </div>

                <p
                  className={`${props.textColor ? props.textColor : "text-black"}
                  w-full lg:w-[140px] h-5  whitespace-nowrap  font-inner-tight text-BodyMedium text-ellipsis overflow-hidden absolute left-8`}
                >
                  {"AI Marketing Tools"}
                </p>
              </div>
              <div
                className={`transition-all transform-gpu ${collapsed ? "h-[0px]" : "h-auto"
                  }`}
              >
                {!collapsed && (
                  <>
                    <button
                      onClick={() => props.onSubItemClick("tts")}
                      className={`
                        bg-menuBg  hover:bg-menuBrandHoverBg
                        h-[38px] pl-[10px] relative rounded
                        ${activePage === "text-to-speech" ? "bg-menuBrandHoverBg" : ""}`}
                    >
                      <div className="flex flex-row items-center pl-3">
                        <SvgLeftMenuTextToSpeechButton
                          className="w-5 h-5"
                        />
                        <p
                          className={`
                            ${props.textColor ? props.textColor : "text-black"} 
                            w-full lg:w-[154px] ml-[15px] whitespace-nowrap text-left font-inner-tight text-BodyMedium text-ellipsis overflow-hidden
                             ${activePage === "text-to-speech" ? 'font-medium' : ''}`}
                        >
                          {"Text to speech"}
                        </p>
                        {
                          activePage === "text-to-speech" && (
                            <span className="border-r-black border-0 border-r-2  absolute -right-4  h-8"></span>
                          )
                        }
                      </div>
                    </button>

                    <button
                      onClick={() => {
                        props.onSubItemClick("background-remover");
                      }}
                      className={`
                        bg-menuBg  hover:bg-menuBrandHoverBg
                          h-[38px] pl-[10px] relative rounded
                          ${activePage === "background-remover" ? "bg-menuBrandHoverBg" : ""}
                        `}
                    >
                      <div className="flex flex-row items-center pl-3 relative">
                        <SvgLeftMenuBackgroundRemoverButton
                          className="w-5 h-5"
                        />
                        <p
                          className={`${props.textColor ? props.textColor : "text-black"
                            } w-full lg:w-[154px] ml-[15px] whitespace-nowrap text-left font-inner-tight text-BodyMedium text-ellipsis overflow-hidden
                             ${activePage === "background-remover" ? 'font-medium' : ''}`}
                        >
                          {"Background Remover"}
                        </p>
                        {
                          activePage === "background-remover" && (
                            <span className="border-r-black border-0 border-r-2  absolute -right-4  h-8"></span>
                          )
                        }
                      </div>
                    </button>
                    {/*<button
                        onClick={() => {
                          props.onSubItemClick("create-polish");
                        }}
                        className={`
                        bg-menuBg  hover:bg-menuBrandHoverBg
                          h-[38px] pl-[10px] relative rounded
                          ${activePage === "create-polish" ? "bg-menuBrandHoverBg" : ""}
                        `}
                    >
                      <div className="flex flex-row items-center pl-3 relative">
                        <SvgLeftMenuBackgroundRemoverButton
                            className="w-5 h-5"
                        />
                        <p
                            className={`${props.textColor ? props.textColor : "text-black"
                            } w-full lg:w-[154px] ml-[15px] whitespace-nowrap text-left font-inner-tight text-BodyMedium text-ellipsis overflow-hidden
                             ${activePage === "create-polish" ? 'font-medium' : ''}`}
                        >
                          {"Create Polish"}
                        </p>
                        {
                            activePage === "create-polish" && (
                                <span className="border-r-black border-0 border-r-2  absolute -right-4  h-8"></span>
                            )
                        }
                      </div>
                    </button>*/}
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {(props.collapsedMode ?? false) && (
          <Tooltip title={"AI Marketing Tools"} placement="right" color="#000000">
            <div className=" h-[38px] flex flex-row items-center align-middle hover:bg-menuBrandHoverBg rounded cursor-pointer">
              <div className="mx-auto">
                <SvgAIMarketingToolsCollapsedIcon
                  fill={"#000"}
                  className="w-5 h-5"
                  height={20}
                  width={20}
                />
              </div>

            </div>
          </Tooltip>

        )}
      </div >
    </>
  );
}

export default AIMarketingToolsSubMenu;
